<template>
    <v-dialog v-model="visible" persistent max-width="700">
            <v-card>
                <v-card-title class="justify-center">
                    Fatura em aberto
                </v-card-title>
                <v-card-text align="center" justify="center" :style="`max-height: ${$vuetify.breakpoint.height - 180}px; overflow-x: auto;`">
                    <div class="mr-6 ml-6" style="font-size: 16px; text-align: left;">
                        Você pode escolher a forma de pagamento na hora de quitar o boleto:<br><br>
                        <ul>
                            <li>
                                Através <b>Pix</b> a confirmação ocorrerá em <b>1 minuto</b>.<br>
                            </li>
                            <li>
                                Através <b>Código de Barras</b> a confirmação ocorrerá em até <b>2 dias úteis</b>.<br><br>
                            </li>
                        </ul>
                    </div>
                    <div class="my-8">
                        <v-btn large class="primary" @click="openUrl">
                            <v-icon class="mr-2">mdi-barcode-scan</v-icon>
                            Visualizar Fatura
                        </v-btn>
                    </div>
                    Caso você ja tenha efetuado o pagamento, envie o comprovante clicando no botão de Ajuda.
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="$emit('close')" text color="warning">Sair</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="goToWhatsapp" color="success">
                        <v-icon>mdi-whatsapp</v-icon>
                        Ajuda
                    </v-btn>
                </v-card-actions>
            </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'BolixPendente',

    props: {
        visible: {
            type: Boolean,
            default: false
        },
        value: {
            type: String,
            default: ''
        },
    },

    data: () => ({
        form: {},
    }),

    methods: {
        openUrl() {
            this.value && window.open(this.value, '_blank');
        },

        goToWhatsapp() {
            window.open('https://api.whatsapp.com/send?phone=5549984329181&text=Ol%C3%A1%2C%20gostaria%20de%20falar%20com%20o%20setor%20financeiro');
        },
    }
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}
</style>
