<template>
    <BaseContainer>
        <span slot="principal">
            <v-content>
                <v-card elevation="0" class="mb-12">
                    <v-card-title class="justify-center">Minha Conta</v-card-title>
                    <v-card-text align="center" justify="center">

                        <card-checkout @onConfirm="signPlan"/>

                        <!-- <v-row>
                            <v-col cols="12">
                                <v-btn
                                    v-if="true"
                                    color="#1fc625"
                                    class="white--text"
                                    large
                                    block
                                    @click="signPlan"
                                >
                                Assinar
                                </v-btn>
                                <v-btn @click="cancel" color="#ddd">Cancelar Assinatura</v-btn>
                            </v-col>
                        </v-row> -->

                    </v-card-text>
                </v-card>
                <v-footer fixed class="pa-4">
                    <!-- blue-grey -->
                    <v-btn link medium color="error" class="white--text" to="/">Sair</v-btn>
                    <v-spacer />
                    <v-chip large color="#1fc625" class="white--text">
                        <v-icon class="mr-1">mdi-shopping</v-icon> R$119,90/mês
                    </v-chip>
                    <!-- <v-btn color="success" large @click="salvar"><v-icon>mdi-content-save</v-icon>&nbsp;Salvar</v-btn> -->
                </v-footer>

                <v-dialog v-model="dialog" :width="this.$vuetify.breakpoint.xsOnly ? '90%' : '60%'" persistent>
                    <v-card>
                        <v-card-title class="text-h5 justify-center" style="word-break: break-word;">
                            Pronto para continuar?
                        </v-card-title>

                        <v-card-text>
                            Assine o plano mensal e continue utilizando o sistema sem limites!
                            <br>
                            <br>
                            Confiamos na excelência do nosso produto e por isso não exigimos contrato fidelidade, você poderá cancelar quando quiser!
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="dialog = false" block>
                                Continuar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer'
import CardCheckout from './CardCheckout.vue'
// import { getPaymentToken } from '@/plugins/efi'

export default {
    components: { BaseContainer, CardCheckout },
    name: 'Account',
    data: () => ({
        form: {
            name: '',
            email: '',
            cpf: '',
            birth: '',
            phone_number: '',
            cardNumber: '',
            validity: '',
            cvv: '',
        },
        dialog: true
    }),
    created() {
        // billing_address: {
        //     street: 'Av. JK',
        //     number: 909,
        //     neighborhood: 'Bauxita',
        //     zipcode: '35400000',
        //     city: 'Ouro Preto',
        //     state: 'MG'
        // },
        // customer: {
        //     name: 'Gorbadoc Oldbuck',
        //     email: 'oldbuck@gerencianet.com.br',
        //     cpf: '94271564656',
        //     birth: '1977-01-15',
        //     phone_number: '5144916523'
        // }
        // this.geter();
    },
    methods: {
        async signPlan(data) {
            console.log(data);
            // const signAndPay = this.$fn.httpsCallable('signAndPay');

            //INCLUIR CNPJ e RAZAO SOCIAL
            // const card =  {
            //     brand: data.brand,
            //     number: data.cardNumber,
            //     cvv: data.cardCvv,
            //     expiration_month: data.cardMonth,
            //     expiration_year: data.cardYear
            // };
            // let paymentToken = null;

            // await getPaymentToken(card).then(resp => (paymentToken = resp.data.payment_token));

            // signAndPay({paymentToken}).then(resp => console.log(resp));
        },
        cancel() {
            const cancelSubscription = this.$fn.httpsCallable('cancelSubscription');
            cancelSubscription({assinatura_id: 52198}).then(resp => console.log(resp));
        },
        // consultar() {
        //     this.$db.collection('configuracoes').doc(this.$dominio).get().then(querySnapshot => {
        //         let data = querySnapshot.data();

        //         this.form = data;
        //     }).finally(() => {
        //         this.loading = false;
        //     });
        // },
        // salvar() {
        //     this.$db.collection('configuracoes').doc(this.$dominio).update(this.form).then(() => {
        //         this.notify('Atualizado com sucesso!');
        //     }).catch(() => {
        //         this.notify('Verifique sua conexão com a internet', 'warning');
        //     });
        // },
    }
}
</script>
