<template>
    <v-menu v-model="open" offset-y left :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                ref="wppButton"
                icon
                :color="getIconColor"
                v-bind="attrs"
                v-on="on"
            >
                <v-icon>mdi-whatsapp</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-list style="text-align: center;">
                <div v-if="wppConected" class="ma-3">
                    <div v-if="whatsappBotActive">
                        <div class="ml-2">
                            <v-chip
                                color="#4CAF50"
                                text-color="white"
                            >
                                <v-icon left>
                                    mdi-check-circle-outline
                                </v-icon>
                                Robô ativo
                            </v-chip>
                        </div>

                        <v-btn
                            width="200"
                            class="mt-4"
                            color="error"
                            text
                            small
                            @click="whatsappBotActive = false"
                            :loading="loading"
                        >
                            Desativar
                        </v-btn>
                    </div>

                    <div v-else>
                        <div class="ml-2">
                            <v-chip
                                color="error"
                                outlined
                            >
                                <v-icon left>
                                    mdi-circle-medium
                                </v-icon>
                                Robô inativo
                            </v-chip>
                        </div>

                        <v-btn
                            width="200"
                            class="mb-2 mt-4"
                            color="success"
                            @click="whatsappBotActive = true"
                            :loading="loading"
                        >
                            Ativar
                        </v-btn>
                    </div>

                    <div class="ml-4 mt-2">
                        {{ userWhatsapp }} - {{ userName }}
                    </div>
                </div>

                <v-btn
                    v-if="!wppConected && (!wppInstanceKey || !wppQrBase64)"
                    width="200"
                    class="ma-2"
                    color="success"
                    @click="connectWpp"
                    :loading="loading"
                >
                    Conectar whatsapp
                </v-btn>

                <v-btn
                    v-if="!wppConected && wppInstanceKey && wppQrBase64"
                    width="200"
                    class="ma-2"
                    color="success"
                    @click="connectWpp"
                    :loading="loading"
                >
                    Atualizar QrCode
                </v-btn>

                <v-btn
                    v-if="wppConected && usuario.tipo === 'suporte'"
                    text
                    outlined
                    width="200"
                    class="ma-2"
                    color="error"
                    @click="deleteWppInstance"
                    :loading="loading"
                >
                    Desconectar
                </v-btn>
                <div v-if="wppInstanceKey && wppQrBase64 && !wppConected" style="color: #444;">
                    <img width="220" height="220" :src="wppQrBase64"/>
                    <div style="font-size: 12px; max-width: 200px; margin: 0px 10px 10px 10px;">
                        <span><b>Siga as instruções:</b></span>
                        <ol class="mt-4">
                            <li>Abra o WhatsApp no seu celular.</li>
                            <li>Toque em <b>Mais opções</b> ou <b>Configurações</b> e selecione Aparelhos conectados.</li>
                            <li>Aponte seu celular para esta tela para capturar o código.</li>
                        </ol>
                    </div>
                </div>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
    name: 'QrCodeWhatsapp',

    data: () => ({
        loading: false,
        wppInstanceKey: '',
        wppConected: false,
        wppQrBase64: '',
        open: false,
        interval: null,
        user: null,
    }),

    mounted() {
        this.infoWppInstance();
        this.ws();
    },

    beforeDestroy() {
        document.removeEventListener('ws-qrcode', this.setWppQrCode);
        document.removeEventListener('ws-open', this.setWppConected);
    },

    watch: {
        open(v) {
            if (v) {
                this.interval = setInterval(() => {
                    this.wppInstanceKey && !this.wppConected && this.qrbase64WppInstance();
                }, 10000);
            } else {
                clearInterval(this.interval);
            }
        },
    },

    computed: {
        ...mapState([
            'usuario',
            'configuracoes',
        ]),

        getIconColor() {
            if (this.loading) {
                return '#707070';
            }

            if (!this.wppConected) {
                return 'error';
            }

            return this.configuracoes?.whatsapp_bot_active ? 'success' : 'warning';
        },

        userName() {
            return this.user?.name || '';
        },

        userWhatsapp() {
            return this.user?.id ? this.user?.id.split(':')[0] : '';
        },

        whatsappBotActive: {
            get() {
                return this.configuracoes.whatsapp_bot_active;
            },

            set(value) {
                this.setConfig({ option: 'whatsapp_bot_active', value });
                this.save();
            },
        },
    },

    methods: {
        ...mapMutations({
            setConfig: 'setConfig',
        }),

        connectWpp() {
            this.wppInstanceKey
                ? this.qrbase64WppInstance()
                : this.initWppInstance();
        },

        async infoWppInstance() {
            this.loading = true;
            const { data } = (await this.$http.get('whatsapp-instance/info')).data;
            this.loading = false;

            this.wppInstanceKey = data?.instance_data?.instance_key || null;
            this.wppConected = data?.instance_data?.phone_connected;
            this.user = data?.instance_data?.user;

            setTimeout(() => this.showConnectWhatsappDialog(), 3000);
        },

        async showConnectWhatsappDialog() {
            const createdAt = this.moment(this.configuracoes.created_at);
            const today = this.moment();
            const thirtyDaysAgo = today.subtract(30, 'days');
            const isMoreThan30DaysAgo = createdAt.isBefore(thirtyDaysAgo);

            const dataAtual = new Date();
            const dataUltimaExecucao = new Date(localStorage.getItem('dateConnectWppDialog'));

            const showConfirmDialog =  (
                !dataUltimaExecucao || dataAtual.getDate() !== dataUltimaExecucao.getDate()
            ) && !isMoreThan30DaysAgo && !this.wppConected;

            if (showConfirmDialog) {
                const confirm = await this.$root.$confirm(
                    'Ative o Robô de Atendimento 🤖',
                    `Conecte o whatsapp do seu estabelecimento, para que o robô responda seus clientes. DESEJA ATIVAR AGORA?`,
                    { color: 'success' }
                );

                if (confirm) {
                    this.$refs.wppButton.$el.click();
                    this.connectWpp();
                }

                localStorage.setItem('dateConnectWppDialog', dataAtual);
            }
        },

        async initWppInstance() {
            this.loading = true;
            const { data } = (await this.$http.get('whatsapp-instance/init')).data;
            this.loading = false;

            if (!data.key) {
                return;
            }

            this.wppInstanceKey = data.key;
        },

        qrbase64WppInstance() {
            this.loading = true;
            this.$http.get(`whatsapp-instance/qrbase64?key=${this.wppInstanceKey}`).then(async resp => {
                if (!resp.data.data.qrcode.trim()) {
                    await this.resetInstance();
                    return;
                }

                this.wppQrBase64 = resp.data.data.qrcode;
            }).finally(() => (this.loading = false));
        },

        async deleteWppInstance() {
            await this.$http.delete(`whatsapp-instance/delete?key=${this.wppInstanceKey}`).then(() => {
                this.wppInstanceKey = '';
                this.wppConected = false;
            });
        },

        async resetInstance() {
            await this.deleteWppInstance();
            await this.initWppInstance();
        },

        ws() {
            document.addEventListener('ws-qrcode', this.setWppQrCode);
            document.addEventListener('ws-open', this.setWppConected);
        },

        setWppConected() {
            this.infoWppInstance();
        },

        setWppQrCode(event) {
            this.wppQrBase64 = event.detail;
        },

        save() {
            this.loading = true;
            const data = { whatsapp_bot_active: this.configuracoes.whatsapp_bot_active };
            this.$http.post('configuracoes/set-config', data)
                .then(resp => {
                    if (resp.data.type == 'warning') {
                        this.notify(resp.data.msg, 'warning');
                        return;
                    }

                    this.notify('Atualizado com sucesso!');
                })
                .catch(() => this.notify('Não foi possivel atualizar', 'warning'))
                .finally(() => (this.loading = false));
        },
    },
}
</script>
