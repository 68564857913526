<template>
    <div>
        <v-dialog
            v-model="visible"
            fullscreen
            hide-overlay
            scrollable
        >
            <v-card>
                <v-toolbar flat dark style="height: 80px">
                    <v-chip class="ma-1" small outlined>
                        <b>R${{ formatPrice(comandaTotalizador) }}</b>
                    </v-chip>
                    <v-spacer/>
                    <v-toolbar-title>
                        {{ value.id === 'auto' ? 'NOVO PEDIDO' : `PEDIDO ${value.id}`}}
                    </v-toolbar-title>
                    <v-spacer/>
                    <v-btn icon dark @click="$emit('close')">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <div style="position: absolute; top: 45px;">
                        <v-chip
                            :disabled="isFinalizado"
                            class="ma-1"
                            small
                            outlined
                            @click="abrirObservacao(null, value.observacao)"
                        >
                            <v-icon>mdi-comment-edit-outline</v-icon>
                            <label style="margin-left: 5px;">
                                Obs.: {{ (value.observacao || '').substr(0, 35) }}
                            </label>
                        </v-chip>
                    </div>
                    <div v-if="value.id != 'auto'" style="position: absolute; top: 40px; right: 15px;">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item v-if="!isFinalizado" @click="pronto">
                                    <v-list-item-title>
                                        <v-icon class="mr-2">
                                            mdi-thumb-{{ value.pronto ? 'down' : 'up' }}
                                        </v-icon>
                                        <span>
                                            {{ `Sinalizar como ${value.pronto ? 'pendente' : 'pronto'}` }}
                                        </span>
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item
                                    v-if="showEmPreparacao"
                                    @click="updateStatus('em_preparacao')"
                                >
                                    <v-list-item-title>
                                        <v-icon class="mr-2">mdi-chef-hat</v-icon>
                                        <span>Em Preparação</span>
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item
                                    v-if="showSaiuParaEntrega"
                                    @click="updateStatus('saiu_para_entrega')"
                                >
                                    <v-list-item-title>
                                        <v-icon class="mr-2">mdi-moped</v-icon>
                                        <span>Saiu p/ Entrega</span>
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item
                                    v-if="showAguardandoRetirada"
                                    @click="updateStatus('aguardando_retirada')"
                                >
                                    <v-list-item-title>
                                        <v-icon class="mr-2">mdi-package-variant-closed-check</v-icon>
                                        <span>Aguardando Retirada</span>
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item
                                    v-if="showConcluido"
                                    @click="updateStatus('concluido')"
                                >
                                    <v-list-item-title>
                                        <v-icon class="mr-2">mdi-checkbox-marked-circle-outline</v-icon>
                                        <span>Concluído</span>
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item
                                    v-if="showCancelar"
                                    @click="updateStatus('cancelado')"
                                >
                                    <v-list-item-title>
                                        <v-icon class="mr-2">mdi-close</v-icon>
                                        <span>Cancelar</span>
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item v-if="value.id != 'auto'" @click="imprimir()">
                                    <v-list-item-title>
                                        <v-icon class="mr-2">mdi-printer</v-icon>
                                        <span>Imprimir Pedido</span>
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item
                                    v-if="hasFiscalModule && value.nota_id && isNotaAprovada(value.nota)"
                                    @click="imprimirNotaSaida"
                                >
                                    <v-list-item-title>
                                        <v-icon class="mr-2">mdi-printer</v-icon>
                                        <span>
                                            Imprimir Nota
                                        </span>
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item
                                    v-if="hasFiscalModule && !isNotaAprovada(value.nota)"
                                    @click="emitirNotaSaida('nfce')"
                                >
                                    <v-list-item-title>
                                        <v-icon class="mr-2">mdi-note</v-icon>
                                        <span>
                                            Emitir NFC-e
                                        </span>
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item
                                    v-if="hasFiscalModule && !isNotaAprovada(value.nota)"
                                    @click="emitirNotaSaida('nfe')"
                                >
                                    <v-list-item-title>
                                        <v-icon class="mr-2">mdi-file-pdf-box</v-icon>
                                        <span>
                                            Emitir NF-e
                                        </span>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </v-toolbar>


                <v-card-text style="height: 80%;">
                    <div style="overflow-x: auto; white-space: nowrap; padding-top: 10px;">
                        <div style="display: flex;">
                            <CustomerSearchAutocomplete
                                ref="cliente"
                                v-model="value.cliente"
                                @update="onClienteUpdate"
                                @reset="value.cliente = null"
                                style="margin-top: -16px;"
                            />
                            <v-select
                                :readonly="isFinalizado"
                                hide-details
                                :items="mesas"
                                class="mx-2"
                                outlined
                                dense
                                label="Mesa"
                                v-model="value.mesa"
                                style="min-width: 80px;"
                            />
                            <v-select
                                :readonly="isFinalizado"
                                hide-details
                                :items="pessoas"
                                class="mx-2"
                                outlined
                                dense
                                label="Pessoas"
                                v-model="value.pessoas"
                                style="min-width: 100px;"
                            />
                            <v-select
                                :readonly="isFinalizado"
                                hide-details
                                :items="formasEntrega"
                                class="mx-2"
                                outlined
                                dense
                                label="Entrega"
                                v-model="value.entrega"
                                item-value="id"
                                item-text="descricao"
                                style="min-width: 120px;"
                            />
                            <v-select
                                v-if="value.entrega == 2"
                                :readonly="isFinalizado"
                                hide-details
                                :items="entregadores"
                                class="mx-2"
                                outlined
                                dense
                                label="Entregador"
                                v-model="value.entregador_id"
                                item-value="id"
                                item-text="nome"
                                style="min-width: 150px;"
                            />
                        </div>
                    </div>
                    <v-data-table
                        fixed-header
                        class="elevation-0"
                        :headers="headers"
                        :items="value.produtos"
                        :mobileBreakpoint="0"
                        :disable-pagination="true"
                        :hide-default-footer="true"
                        :height="$vuetify.breakpoint.height - 230"
                    >
                        <template v-slot:item="{ item }">
                            <tr>
                                <td style="padding-bottom: 10px;">
                                    <v-icon v-if="item.premio">mdi-gift-outline</v-icon>
                                    {{ item.descricao }}
                                    <i style="color: red;">{{(item.observacao || '').substr(0, 48)}}</i><br>
                                    <div style="display: flex;">
                                        <v-btn
                                            :disabled="isFinalizado"
                                            small
                                            icon
                                            color="black"
                                            @click.stop="comandaRemoverQtd(item.index)"
                                        >
                                            <v-icon large>mdi-minus-box</v-icon>
                                        </v-btn>
                                        <span class="product-qtd">
                                            {{item.quantidade}}
                                        </span>
                                        <v-btn
                                            :disabled="isFinalizado"
                                            small
                                            icon
                                            color="black"
                                            @click.stop="comandaAdicionarQtd(item.index)"
                                        >
                                            <v-icon large>mdi-plus-box</v-icon>
                                        </v-btn>
                                    </div>
                                </td>
                                <td style="text-align: center; min-width: 100px;">
                                    <template v-if="!item.editarValor">
                                        <span>R$</span>
                                        <span>{{ formatPrice(+item.valor || 0) }}</span>
                                        <!-- Não é possivel editar valor de combo pois ocorre erro na emissão de nota -->
                                        <!-- Quando combo, o valor editavel deveria ser por item do combo -->
                                        <v-icon
                                            v-if="!item.hasOwnProperty('produtos')"
                                            @click="editItemValue(item)"
                                            small
                                        >mdi-pencil</v-icon>
                                    </template>
                                    <template v-else>
                                        <span style="margin-right: -20px;">R$</span>
                                        <input
                                            @change="atualizarTotais"
                                            type="text"
                                            v-model="item.valor"
                                            v-mask="['#.##','##.##','###.##']"
                                            style="width: 68px; border: solid 1px #585858; border-radius: 4px; padding-left: 20px;"
                                        >
                                    </template>
                                    <br>
                                    <v-btn
                                        :disabled="isFinalizado"
                                        outlined
                                        small
                                        depressed
                                        tile
                                        color="primary"
                                        @click.stop="abrirObservacao(item, item.observacao)"
                                    >
                                        Obs
                                    </v-btn>
                                </td>
                                <td style="text-align: center;">
                                    <span :style="item.premio ? 'text-decoration: line-through;' : ''">
                                        R${{ formatPrice(item.total) }}
                                    </span>
                                    <br>
                                    <v-btn
                                        outlined
                                        small
                                        depressed
                                        tile
                                        color="primary"
                                        @click.stop="abrirProdutoDetalhes(item)"
                                    >
                                        <v-icon>mdi-format-list-bulleted</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>

                <v-card-actions style="display: flex; justify-content: space-between; width: 100%;">
                    <v-btn
                        :disabled="isFinalizado"
                        v-if="value.id != 'auto'"
                        color="info"
                        @click="checkoutComanda()"
                        style="width: 32%"
                    >
                        PAGAMENTO
                    </v-btn>

                    <v-btn
                        color="indigo"
                        class="white--text"
                        @click="abrirComandaDetalhes()"
                        style="width: 32%"
                    >
                        DETALHES
                    </v-btn>

                    <v-btn
                        :disabled="isFinalizado"
                        color="success"
                        @click="onClickSalvar()"
                        :style="value.id != 'auto' ? 'width: 32%' : 'width: 65%'"
                    >
                        <v-icon>mdi-content-save</v-icon>
                        SALVAR
                    </v-btn>
                </v-card-actions>
            </v-card>

            <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        :disabled="isFinalizado"
                        @click="dialogProdutos = true"
                        fab
                        fixed
                        right
                        bottom
                        x-large
                        color="primary"
                        style="margin-bottom: 50px;"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon>mdi-food-fork-drink</v-icon>
                    </v-btn>
                </template>
                <span>Adicionar produtos</span>
            </v-tooltip>
        </v-dialog>

        <v-dialog
            v-model="dialogProdutos"
            fullscreen
            hide-overlay
            scrollable
        >
            <v-card tile>
                <v-toolbar :disabled="isFinalizado" flat dark>
                    <v-btn icon dark @click="onPesquisaProdutoClick()">
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title>Adicionar Produtos</v-toolbar-title>
                    <v-spacer/>
                    <v-btn icon dark @click="dialogProdutos = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-text-field
                    v-model="pesquisaProduto.value"
                    ref="pesquisaProduto"
                    dense
                    outlined
                    clearable
                    hide-details
                    color="black"
                    label="Pesquise o produto"
                    :style="'position: absolute; top: 10px; left: 70px; background-color: #e9e9e9; z-index: 99;'
                        + (pesquisaProduto.hidden ? 'display: none;' : '')"
                />
                <v-card-text style="height: 80%;">
                    <div style="display: flex; width: 95%; position: fixed; top: 70px; left: 10px; z-index: 99;"
                        @click="onPesquisaProdutoClick('hide')">
                        <v-icon>mdi-chevron-left</v-icon>
                        <div style="overflow-x: auto; white-space: nowrap;">
                            <v-btn
                                v-for="grupo in produtosGrupos" :key="grupo.descricao"
                                color="white"
                                large
                                @click="filtroByGrupo = grupo.descricao"
                            >
                                {{ grupo.descricao }}
                            </v-btn>
                        </div>
                        <v-icon>mdi-chevron-right</v-icon>
                    </div>

                    <v-data-table
                        hide-default-header
                        fixed-header
                        :headers="headersProdutos"
                        :mobileBreakpoint="0"
                        :items="getProdutos()"
                        :hide-default-footer="true"
                        :disable-pagination="true"
                        style="margin-top: 60px;"
                        sort-by="descricao"
                        :height="$vuetify.breakpoint.height - 230"
                    >
                        <template v-slot:item="{ item }">
                            <tr @click="produtoAdicionarQtd(item.produto_id, item.grupo.id)">
                                <td style="padding: 0;">
                                    <v-badge
                                        v-if="item.quantidade > 0"
                                        offset-x="0"
                                        offset-y="40"
                                        color="primary"
                                        :content="item.quantidade"
                                        style="z-index: 1;"
                                    />
                                    <v-img
                                        :src="getUrl(item.imagem)"
                                        width="60"
                                        height="60"
                                    />
                                </td>
                                <td>
                                    <div>
                                        {{ item.descricao }}
                                        <v-chip
                                            v-if="isIndisponivel(item)"
                                            class="white--text ml-1"
                                            color="error"
                                            x-small
                                        >
                                            {{ isIndisponivel(item) }}
                                        </v-chip>
                                    </div>
                                </td>
                                <td>
                                    {{ formatPrice(item.valor) }}
                                </td>
                                <td style="padding: 0px;">
                                    <v-btn
                                        :disabled="isFinalizado"
                                        v-if="item.quantidade > 0"
                                        color="error"
                                        x-small
                                        @click.stop="produtoRemoverQtd(item.produto_id)"
                                    >
                                        <v-icon x-small>mdi-minus</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>

                <v-card-actions>
                    <v-chip class="ma-1" label small outlined>
                        <b>SELECIONADO R$ {{ formatPrice(totalSelecionado) }}</b>
                    </v-chip>
                    <v-spacer/>
                    <v-btn color="success" @click="comandaAdicionar" style="width: 190px;">Adicionar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogObservacao" persistent>
            <v-card>
                <v-card-title class="mb-6">
                    <span>Observação</span>
                    <v-spacer/>
                    <v-btn icon @click="dialogObservacao = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text :style="`height: ${$vuetify.breakpoint.height - 350}px;`">
                    <v-textarea
                        clearable
                        clear-icon="mdi-backspace-outline"
                        v-model="observacao.text"
                        placeholder="Ex: Remover tomate..."
                        outlined
                        height="420"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        @click="dialogObservacao = false"
                        color="warning"
                        text
                    >Cancelar</v-btn>
                    <v-spacer/>
                    <v-btn
                        @click="adicionarObservacao"
                        color="primary"
                        large
                    >
                        <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <DialogDetalhes
            v-model="value"
            :visible="dialogDetalhes.visible"
            :item="dialogDetalhes.item"
            :formasPagamento="formasPagamento"
            :formasEntrega="formasEntrega"
            @close="onDialogDetalhesClose"
            @editarCliente="$refs.cliente.openDialogCliente()"
        />

        <DialogSteps
            v-model="dialogSteps.item"
            :visible="dialogSteps.visible"
            @finish="onStepsFinish"
            @close="dialogSteps = { visible: false }"
        />
    </div>
</template>

<script>
import DialogSteps from './DialogSteps.vue';
import DialogDetalhes from './DialogDetalhes.vue';
import CustomerSearchAutocomplete from './Pedido/CustomerSearchAutocomplete.vue';
import { subtotal, subtotalGeral } from '@/utils/calc';
import { clone, printDanfe } from '@/utils/utils';
import { hasModule } from '@/utils/module';
import { mapState } from 'vuex';

export default {
    name: 'DialogComanda',

    components: {
        DialogSteps,
        DialogDetalhes,
        CustomerSearchAutocomplete,
    },

    props: {
        visible: {
            type: Boolean,
            default: false,
        },

        value: {
            type: Object,
        },
    },

    data: () => ({
        pesquisaProduto: {
            value: '',
            hidden: true,
        },
        filtroProduto: '',
        filtroByGrupo: '',
        comandaTotalizador: 0,
        totalSelecionado: 0,
        mesas: Array.from({length: 50}, (_, i) => i + 1),
        pessoas: Array.from({length: 25}, (_, i) => i + 1),
        dialogDetalhes: {
            visible: false,
            item: {},
            comanda: {},
        },
        dialogSteps: {
            visible: false,
            item: {},
        },
        dialogProdutos: false,
        dialogObservacao: false,
        observacao: {item: '', text: ''},
        headersProdutos: [
            {text: 'Qtd', value: 'quantidade', sortable: false, align: 'center'},
            {text: 'Item', value: 'descricao', sortable: false, align: 'start'}, // start center end
            {text: 'Valor', value: 'valor', sortable: false, align: 'center'},
            {text: '', value: 'acoes', sortable: false, align: 'center'},
        ],
        headers: [
            {text: 'Item', value: 'item', sortable: false, align: 'start'}, // start center end
            {text: 'Valor', value: 'valor', sortable: false, align: 'center'},
            {text: 'Total', value: 'total', sortable: false, align: 'center'},
        ],
    }),

    computed: {
        ...mapState([
            'usuario',
            'produtos',
            'produtosGrupos',
            'configuracoes',
            'entregadores',
            'formasEntrega',
            'formasPagamento',
        ]),

        isFinalizado() {
            return this.value.finalizado;
        },

        showEmPreparacao() {
            return !this.isFinalizado &&
                this.value.entrega != 3 &&
                this.value.status === 'confirmado';
        },

        showSaiuParaEntrega() {
            return !this.isFinalizado &&
                this.value.entrega == 2 &&
                ['em_preparacao', 'confirmado'].includes(this.value.status);
        },

        showAguardandoRetirada() {
            return !this.isFinalizado &&
                this.value.entrega == 1 &&
                ['em_preparacao', 'confirmado'].includes(this.value.status);
        },

        showCancelar() {
            return this.value.status != 'cancelado';
        },

        showConcluido() {
            return !this.isFinalizado && this.value.status != 'concluido';
        },

        requireSupervisorPassword() {
            return !this.usuario.admin && this.configuracoes.supervisor_password;
        },

        hasFiscalModule() {
            return hasModule('FISCAL');
        },
    },

    watch: {
        visible(v) {
            v && this.atualizarTotais();

            this.value.produtos.map(p => (p.editarValor = false));

            !v && this.$emit('close');
        },
    },

    methods: {
        pronto() {
            this.value.pronto = !this.value.pronto;
            this.$forceUpdate();
        },

        async onClickSalvar() {
            const data = await this.save();

            if (data?.hasPendingPrints && this.configuracoes.impressao?.imprimirAlteracoes) {
                this.$emit('imprimir', { pedido: this.value, tipo: 'resumido' });
            }
        },

        async save() {
            if (!this.value.cliente_id) {
                this.notify('Informe o cliente', 'warning');
                return;
            }

            const endereco = this.value.cliente?.enderecos[0];

            if (endereco) {
                this.value.endereco = {
                    uf: endereco.uf,
                    cep: endereco.cep,
                    rua: endereco.rua,
                    bairro: endereco.bairro,
                    numero: endereco.numero,
                    municipio: endereco.municipio,
                    complemento: endereco.referencia,
                    coordenadas: endereco.coordenadas,
                    tipo_local: endereco.tipo_local,
                };

                if (endereco.tipo_local === 'apartamento') {
                    this.value.endereco.nome_edificio = endereco.nome_edificio;
                    this.value.endereco.numero_apartamento = endereco.numero_apartamento;
                }
            }

            if (this.value.id == 'auto') {
                this.$root.$emit('loading', true);
                return await this.$http.post('pedidos', this.value)
                    .then(resp => {
                        if (resp.data.type == 'warning') {
                            this.notify(resp.data.msg, 'warning');
                            return;
                        }

                        this.notify('Salvo com sucesso!');
                        this.$emit('close');
                    })
                    .catch(() => this.notify('Não foi possivel salvar', 'warning'))
                    .finally(() => this.$root.$emit('loading', false));
            }

            if (this.requireSupervisorPassword) {
                this.value.supervisor_password = prompt('Digite a senha de supervisão');

                if (!this.value.supervisor_password) {
                    return;
                }
            }

            try {
                this.$root.$emit('loading', true);
                const { data } = await this.$http.put(`pedidos/${this.value.id}`, this.value);

                this.$root.$emit('loading', false);

                if (data.type == 'warning') {
                    this.notify(data.msg, 'warning');
                    return;
                }

                this.notify('Atualizado com sucesso!');
                this.$emit('close');

                return data;
            } catch (e) {
                this.notify('Não foi possivel salvar', 'warning')
                this.$root.$emit('loading', false);
            }
        },

        comandaAdicionarQtd(index) {
            (this.value.produtos.find(produto => produto.index == index)).quantidade++;
            this.atualizarTotais();
        },

        comandaRemoverQtd(index) {
            this.value.produtos = this.value.produtos.filter(produto => {
                if (produto.index == index && produto.quantidade > 0) {
                    produto.quantidade--;
                }

                return produto.quantidade > 0;
            });

            this.atualizarTotais();
        },

        isIndisponivel(produto) {
            if (produto.status == 'inativo') {
                return 'inativo';
            }

            const outOfStock = this.configuracoes.controle_estoque && produto.controle_estoque && produto.estoque <= 0;
            return outOfStock ? 'sem estoque' : false;
        },

        async produtoAdicionarQtd(produto_id, grupo_id) {
            const produto = this.produtos.find(p => p.produto_id == produto_id && p.grupo.id == grupo_id);

            // const isIndisponivel = this.isIndisponivel(produto);
            // const msg = 'Produto indisponível, deseja adicionar mesmo assim?';
            // if (isIndisponivel && !await this.$root.$confirm('Confirmação', msg, { color: 'red darken-1' })) {
            //     return;
            // }

            let comboWithSteps = false;
            produto.produtos?.forEach(c => (c.passos?.length && (comboWithSteps = true)));
            const hasSteps = produto?.passos?.length || comboWithSteps;

            //TODO: ajustar para conseguir adicionar varios itens com etapas sem precisar adicionar um a um
            // poderia duplicar o item na listagem...
            if (hasSteps && produto.quantidade > 0) {
                this.notify('Item já escolhido, adicione na comanda antes de continuar', 'warning');
                return;
            }

            if (hasSteps) {
                this.dialogSteps = { visible: true, item: produto };
                return;
            }

            produto.quantidade++;

            this.atualizarTotais();
        },

        produtoRemoverQtd(id) {
            this.produtos.forEach(d => {
                if (id == d.produto_id && d.quantidade > 0) {
                    d.quantidade--;
                }
            });
            this.atualizarTotais();
        },

        atualizarTotais() {
            this.value.produtos.map(i => (i.total = subtotal(i)));

            this.comandaTotalizador = subtotalGeral(this.value.produtos);

            const valorFrete = this.value.entrega == 2 ? (+this.value.valor_frete || 0) : 0;

            this.comandaTotalizador +=
                valorFrete
                + (+this.value.acrescimo || 0)
                - (+this.value.desconto || 0);

            this.updateTotalSelecionado();

            this.$forceUpdate();
        },

        comandaAdicionar() {
            const adicionar = i => {
                i = clone(i);
                i.passos = i.__passos__;

                i.produtos?.forEach(produto => {
                    produto.produto_id = clone(produto.produto_id || produto.id);
                    produto.id = null;
                });

                i.id = null;

                if (i.hasOwnProperty('produtos')) {
                    i.combo_id = clone(i.combo_id || i.produto_id);
                    i.produto_id = null;
                } else {
                    i.produto_id = clone(i.produto_id || i.produto_id);
                }

                // let comboWithSteps = false;
                if (i?.produtos?.length) {
                    i.produtos.forEach(produto => {
                        produto.passos = produto.__passos__;
                        // produto.passos?.length && (comboWithSteps = true);
                    });
                }

                // const hasSteps = i.passos?.length || comboWithSteps;

                // let match = false;
                // this.value.produtos.forEach(c => {
                //     if (c.produto_id == i.produto_id && c.grupo_id == i.grupo_id && !hasSteps) {
                //         c.quantidade = +c.quantidade + +i.quantidade;
                //         match = true;
                //     }
                // });
                // !match && this.value.produtos.push(i);

                // Adequado para sempre lançar os produtos separadamente pela dialog de produtos
                this.value.produtos.push(i);
            };

            this.produtos.forEach(e => {
                (e.quantidade > 0) && adicionar(e);
                e.quantidade = 0;
            });

            this.atualizarTotais();
            this.dialogProdutos = false;
        },

        abrirObservacao(item, text) {
            this.observacao = { item, text };
            this.dialogObservacao = true;
        },

        abrirComandaDetalhes() {
            this.dialogDetalhes = {
                visible: true,
            };
        },

        abrirProdutoDetalhes(item) {
            this.dialogDetalhes = {
                visible: true,
                item,
            };
        },

        adicionarObservacao() {
            if (!this.observacao.item) {
                this.value.observacao = this.observacao.text;
            } else {
                const produto = this.observacao.item;

                const produtos = this.value.produtos.filter(i => {
                    (produto.index == i.index) && (i.observacao = this.observacao.text);
                    return i;
                });

                this.value.produtos = produtos;
            }
            this.dialogObservacao = false;
        },

        onPesquisaProdutoClick(hide) {
            this.pesquisaProduto.hidden = hide || !this.pesquisaProduto.hidden;

            if (this.pesquisaProduto.hidden) {
                this.pesquisaProduto.value = '';
                return;
            }

            this.$nextTick(() => this.$refs.pesquisaProduto?.focus());
        },

        getProdutos() {
            let products = [];
            const search = this.pesquisaProduto.value?.toLowerCase();

            if (!search) {
               products = this.filtroByGrupo
                ? this.produtos.filter(produto => produto.grupo.descricao == this.filtroByGrupo)
                : this.produtos;

                products.forEach((produto, index) => {
                    produto.index = index + 1;
                    return produto;
                });

                return products;
            }

            products = this.produtos.filter(produto => produto.descricao.toLowerCase().includes(search));

            products.forEach((produto, index) => {
                produto.index = index + 1;
                return produto;
            });

            return products;
        },

        onStepsFinish({produto_id, grupo, passos, produtos}) {
            this.dialogSteps = { visible: false, item: null };

            const grupo_id = grupo.id;
            const produto = this.produtos.find(p => p.produto_id == produto_id && p.grupo.id == grupo_id);

            // Combo
            if (produtos?.length) {
                produto.produtos.forEach((p, index) => {
                    if (!p.passos?.length) {
                        return;
                    }

                    p.__passos__ = produtos[index].passos;
                })
            }

            produto.__passos__ = passos;
            produto.quantidade++;

            this.atualizarTotais();
        },

        imprimir(tipo = 'completo') {
            const data = {
                pedido: this.value.id,
                tipo,
            };
            this.$root.$emit('imprimir', data);
        },

        async checkoutComanda() {
            const data = await this.save();

            if (data?.type === 'success') {
                this.$store.commit('setData', {id: this.value.id});
                this.$router.push('/checkout');
            }
        },

        getUrl(img) {
            if (!img?.url) {
                return require('@/assets/noimageavailable.png');
            }

            const url = img.tmp
                ? `${this.$urlAPI}/files/tmp/${img.url}`
                : `${this.$urlAPI}/storage/${img.url}`;

            return url;
        },

        async updateStatus(status) {
            if (status === 'cancelado') {
                const confirm = await this.$root.$confirm(
                    'Confirmação',
                    'Deseja realmente cancelar?',
                    { color: 'red darken-1' }
                );

                if (!confirm) {
                    return;
                }
            }

            this.value.status = status;
            this.save();
        },

        onClienteUpdate(cliente) {
            this.value.cliente = cliente;
            this.value.cliente_id = cliente?.id;

            const endereco = cliente?.enderecos[0];
            this.value.valor_frete = endereco?.valor_frete || 0;
        },

        editItemValue(item) {
            const id = item?.combo_id || item.produto_id;

            const produtos = this.value.produtos.filter(i => {
                let vId = i?.combo_id || i.produto_id;
                (vId == id) && (i.editarValor = true);
                return i;
            });

            this.value.produtos = produtos;
        },

        onDialogDetalhesClose() {
            this.dialogDetalhes = { visible: false };
            this.atualizarTotais();
        },

        updateTotalSelecionado() {
            this.totalSelecionado = this.produtos.reduce((a, b) => a + (+b.quantidade * +b.valor || 0), 0);
        },

        imprimirNotaSaida() {
            printDanfe(this.value.nota);
        },

        emitirNotaSaida(modelo) {
            this.$root.$emit('loading', true);
            this.$http.post('fiscal/emissao-nota-saida', { pedido_id: this.value.id, modelo })
                .then(resp => {
                    if (resp.data.type == 'warning') {
                        this.notify(resp.data.msg, 'warning');
                        return;
                    }
                    const data = resp.data.data;

                    if (data?.nfe?.status === 'aprovado') {
                        this.notify(`Aprovado, ${data.nfe.motivo}`);
                    } else {
                        this.notify(`${data.nfe.status} ${data.nfe.motivo}`, 'warning');
                    }
                })
                .catch(() => {
                    this.notify('Verifique sua conexão com a internet', 'warning');
                }).finally(() => (this.$root.$emit('loading', false)));
        },

        isNotaAprovada(nota) {
            return nota?.status === 'aprovado';
        },
    },
}
</script>

<style scoped>
.product-qtd {
    width: 20px;
    text-align: center;
    font-weight: bold;
    margin: 2px 5px 0 5px;
    font-size: 16px;
}

.class-on-data-table table {
    table-layout: fixed;
}

.v-card__text, .v-card__title {
    word-break: normal;
    /* maybe !important  */
}

.v-card__title {
    padding: 0 16px 0 16px;
    font-size: 15px;
    height: 35px !important;
}

.v-badge {
    display: inline;
}
</style>
