<template>
    <v-dialog v-model="visible" persistent max-width="800px">
        <v-card>
            <v-card-title class="justify-center">
                <v-spacer/>
                <span class="text-h5">Nota de Entrada</span>
                <v-spacer/>
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <FileInput
                            v-model="form.arquivo"
                            @input="upload"
                            ref="file"
                            label="Selecionar XML"
                            icon="upload"
                            accept=".xml"
                            validateType="xml"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="form.cnpj"
                            label="CNPJ"
                            v-mask="['##.###.###/####-##']"
                            filled
                            readonly
                            hide-details
                            outlined
                            dense
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="form.razaoSocial"
                            label="Razão Social"
                            filled
                            readonly
                            hide-details
                            outlined
                            dense
                        />
                    </v-col>
                </v-row>
                <v-data-table
                    fixed-header
                    :headers="headers"
                    :items="form.produtos"
                    :mobileBreakpoint="0"
                    :height="400"
                    :disable-pagination="true"
                    :hide-default-footer="true"
                >
                    <template v-slot:item.valor="{ item }">
                        <span style="color: green;">
                            R${{ formatPrice(item.valor) }}
                        </span>
                    </template>
                    <template v-slot:item.total="{ item }">
                        <span style="color: green;">
                            R${{ formatPrice(item.total) }}
                        </span>
                    </template>
                    <template v-slot:item.produto="{ item }">
                        <v-autocomplete
                            @change="onProductSelect(item)"
                            v-model="item.produto_id"
                            :items="getProdutos"
                            clearable
                            outlined
                            dense
                            hide-details
                            style="width: 200px;"
                        />
                    </template>
                    <template v-slot:item.unidadeMedida="{ item }">
                        <v-select
                            v-model="item.unidade_medida_id"
                            :items="unidadesMedida"
                            outlined
                            dense
                            hide-details
                            style="width: 180px;"
                        >
                            <template #append-item>
                                <div
                                    style="font-size: 14px; font-weight: bold; padding: 14px; cursor: pointer;"
                                    @click="newUndMedida"
                                >
                                    <v-icon>mdi-plus</v-icon> Cadastrar Nova Unidade de Medida
                                </div>
                            </template>
                        </v-select>
                    </template>
                    <!-- <template v-slot:item.categoriaImposto="{ item }">
                        <v-autocomplete
                            v-model="item.categoriaImposto"
                            :items="categoriasImposto"
                            outlined
                            dense
                            hide-details
                            style="width: 200px;"
                        />
                    </template> -->
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="warning" @click="close">Cancelar</v-btn>
                <v-spacer />
                <v-btn large color="primary" @click="send" :loading="loading">
                    <v-icon>mdi-cog-outline</v-icon>&nbsp;Processar
                </v-btn>
            </v-card-actions>
        </v-card>

        <CadastroUnidadeMedida
            :visible="unidadeMedidaVisible"
            :formData="unidadeMedidaForm"
            @onCadastro="onCadastroUnidadeMedida"
            @close="closeUnidadeMedida"
        />
    </v-dialog>
</template>

<script>
import CadastroUnidadeMedida from '@/pages/cadastro/UnidadeMedida';
import FileInput from '@/components/FileInput';
import { convertToJson } from '@/utils/xml';
import { mapState } from 'vuex';

export default {
    name: 'NotaEntrada',

    components: {
        FileInput,
        CadastroUnidadeMedida,
    },

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        loading: false,
        unidadeMedidaVisible: false,
        unidadeMedidaForm: {},
        form: {},
        unidadesMedida: [],
        categoriasImposto: [],
        headers: [
            { text: 'Nome', value: 'descricao' },
            { text: 'Quantidade', value: 'quantidade' },
            { text: 'Vlr. Unit', value: 'valor' },
            { text: 'Total', value: 'total' },
            { text: 'Produto', value: 'produto' },
            { text: 'Unidade Medida', value: 'unidadeMedida' },
            // { text: 'Categoria Imposto', value: 'categoriaImposto' },
        ],
    }),

    mounted() {
        this.retrieveUnidadesMedida();
        this.retrieveCategoriasImposto();
    },

    computed: {
        ...mapState([
            'produtos',
        ]),

        getProdutos() {
            const produtos = this.produtos.map((e, index) => ({
                index,
                value: e.produto_id,
                text: `${e.produto_id} - ${e.descricao}`,
            }));

            return produtos
        },
    },

    methods: {
        retrieveUnidadesMedida(unidadeMedida = null) {
            this.$http.get('unidades-medida', {}).then(resp => {
                if (resp.data.type === 'warning') {
                    this.notify(resp.data.msg, 'warning');
                    return;
                }

                this.unidadesMedida = resp.data.data?.map(e => ({
                    value: e.id,
                    text: `${e.abreviacao} - ${e.nome}`,
                    quantidade: e.quantidade,
                }));

                unidadeMedida && (this.form.unidadeMedida = unidadeMedida);
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            });
        },

        retrieveCategoriasImposto() {
            this.$http.get('categorias-imposto', {}).then(resp => {
                if (resp.data.type === 'warning') {
                    this.notify(resp.data.msg, 'warning');
                    return;
                }

                this.categoriasImposto = resp.data.data?.map(e => ({
                    value: e.id,
                    text: e.nome,
                }));
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            });
        },

        upload(data) {
            if (!data?.file) {
                this.form = {};
                return;
            }

            const reader = new FileReader();
            reader.onload = e => {
                const xmlContent = e.target.result;

                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(xmlContent, 'application/xml');
                const json = convertToJson(xmlDoc);

                this.onLoadFile(json);
            };
            reader.readAsText(data.file);
        },

        onLoadFile(xml) {
            const nfe = xml.nfeProc.NFe.infNFe;
            const nfeProdutos = nfe.det?.prod ? [ nfe.det ] : nfe.det;

            const produtos = nfeProdutos.map(e => ({
                codigo: e.prod.cProd,
                ncm: e.prod.NCM,
                unidadeMedida: e.prod.uCom,
                descricao: e.prod.xProd,
                quantidade: (+e.prod.qCom).toFixed(2),
                valor: (+e.prod.vUnCom).toFixed(2),
                total: e.prod.vProd,
                cfop: e.prod.CFOP,
            }));

            this.form = {
                ...this.form,
                ...{
                    cnpj: nfe.emit.CNPJ,
                    razaoSocial: nfe.emit.xNome,
                    inscricaoEstadual: nfe.emit.IE,
                    nfe: nfe.ide.nNF,
                    serie: nfe.ide.serie,
                    chave: xml.nfeProc.protNFe.infProt.chNFe,
                    produtos,
                }
            };
        },

        send() {
            // if (!this.$refs.form.validate()) {
            //     this.notify('Preencha todos os campos', 'warning');
            //     return;
            // }

            // validar categoria de imposto
            // validar unidade de medida
            // validar produtos e alertar (não é obrigatorio o vinculo de todos)

            this.loading = true;
            this.$http.post('fiscal/emissao-nota-entrada', this.form).then(resp => {
                if (resp.data.type === 'warning') {
                    this.notify(resp.data.msg, 'warning');
                    return;
                }

                this.notify('Processado com sucesso');
                this.close();
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.loading = false));
        },

        onProductSelect(item) {
            console.log(item.id);
            // set item.unidade_medida
            // set item.categoria_imposto
        },

        newUndMedida() {
            this.unidadeMedidaForm = {};
            this.unidadeMedidaVisible = true;
        },

        onCadastroUnidadeMedida(unidadeMedida = null) {
            this.unidadeMedidaVisible = false;
            this.retrieveUnidadesMedida(unidadeMedida);
        },

        closeUnidadeMedida() {
            this.unidadeMedidaVisible = false;
        },

        close() {
            this.form = {};
            this.$emit('close');
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0;
}

.v-fieldset {
    border-color: #999;
    border-radius: 5px;
    padding: 8px;
}

.v-legend {
    color: #707070;
}

input[type="file"] {
    display: none;
}

label {
    padding: 10px 10px;
    width: 170px;
    height: 44px;
    background-color: #333;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    display: block;
    margin-top: 25px;
    cursor: pointer;
}

.btn-file {
    border-radius: 5px;
}
</style>
