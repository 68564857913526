<template>
    <div>
        <v-menu offset-y v-model="isMenuOpen" max-height="450">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="searchInput"
                    v-on="on"
                    v-bind="attrs"
                    @keypress="isMenuOpen = true"
                    :loading="searchLoading"
                    label="Contato"
                    placeholder="Digite para pesquisar"
                    prepend-inner-icon="mdi-magnify"
                    class="mb-2"
                    style="min-width: 200px;"
                    clearable
                    hide-details
                    outlined
                    dense
                />
            </template>
            <v-list>
                <template v-if="contatos.length">
                    <v-list-item
                        v-for="(contato, index) in contatos"
                        :key="index"
                        @click="onSelect(contato.id)"
                    >
                        <v-list-item-title>
                            {{ contato.id }} - {{ contato.nome }} {{ contato.whatsapp ? ` - ${contato.whatsapp}` : '' }}
                        </v-list-item-title>
                    </v-list-item>
                </template>
                <v-list-item v-else style="display: flex; justify-content: center;">
                    {{ !searchLoading && searchInput && !contatos.length ? 'Nenhum registro encontrado' : 'Pesquise pelo código, nome ou whatsapp' }}
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: 'LeadSearchAutocomplete',

    props: {
        value: {
            type: Number,
        },

        returnObject: {
            type: Boolean,
            default: true,
        },
    },

    data: () => ({
        isMenuOpen: false,
        searchLoading: false,
        contatos: [],
        contato: null,
        searchInput: null,
    }),

    watch: {
        searchInput(v) {
            if (v === null) {
                this.reset();
                return;
            }

            this.startSearchTimer();
        },

        'value'(v) {
            v ? this.setInList() : this.reset();
        },
    },

    methods: {
        setInList() {
            if (!this.value) {
                return;
            }

            this.onSelect(this.value);

            // const index = this.clientes.findIndex(c => c.id == this.value.id);
            // (index === -1) && this.clientes.push(this.value);

            // this.cliente = this.value;
            // this.setCliente(this.value);
        },


        onSelect(contato) {
            if (!contato) {
                return;
            }

            this.contatos = this.contatos.filter(e => e.id == contato);

            this.$root.$emit('loading', true);
            this.$http.get(`contatos/${contato}`).then(({ data }) => {
                this.setContato(data);
                this.$emit('update', this.returnObject ? data : data.id);
            }).finally(() => this.$root.$emit('loading', false));
        },

        setContato(contato) {
            this.contato = contato;
            this.searchInput = `${contato.id} - ${contato.nome}`;
        },

        startSearchTimer() {
            const search = this.searchInput || '';
            if (search.length < 2) {
                this.contatos = [];
                return;
            }

            this.performSearch();
        },

        performSearch() {
            const search = this.searchInput || '';

            this.searchLoading = true;
            this.$http.post(`contatos/find?search=${search}`).then(resp => {
                this.contatos = resp.data;
            }).finally(() => (this.searchLoading = false));
        },

        reset() {
            this.contatos = [];
            this.contato = null;
            this.searchInput = null;
            this.$emit('update', null);
        },
    },
}
</script>

<style scoped>
.v-list-item__title {
    height: 20px;
}

.v-list-item {
    height: 20px;
}
</style>
