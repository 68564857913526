import { render, staticRenderFns } from "./NfeDialog.vue?vue&type=template&id=87e49d12&scoped=true&"
import script from "./NfeDialog.vue?vue&type=script&lang=js&"
export * from "./NfeDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87e49d12",
  null
  
)

export default component.exports