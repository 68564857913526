<template>
    <BaseContainer>
        <span slot="principal">
            <v-content>
                <v-card elevation="0" class="mb-12">
                    <v-card-title class="justify-center">
                        {{ form.id ? 'Edição' : 'Cadastro' }} de Colaborador
                    </v-card-title>
                    <v-card-text align="center" justify="center">
                        <v-text-field
                            v-model="form.name"
                            label="Nome"
                            outlined
                            dense
                            hide-details
                        />
                        <v-text-field
                            v-model="form.email"
                            label="Email"
                            type="email"
                            class="mt-3"
                            outlined
                            dense
                            hide-details
                            @input="form.email = form.email.toLowerCase()"
                        />
                        <v-text-field
                            v-model="form.nova_senha"
                            label="Nova Senha"
                            :type="showPassword ? 'text' : 'password'"
                            @click:append="toggleShowPassword('showPassword')"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            class="mt-3"
                            outlined
                            dense
                            hide-details
                        />
                        <v-text-field
                            v-model="form.repetir_senha"
                            label="Repetir Senha"
                            :type="showPasswordConfirm ? 'text' : 'password'"
                            @click:append="toggleShowPassword('showPasswordConfirm')"
                            :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                            class="mt-3"
                            outlined
                            dense
                            hide-details
                        />
                        <v-select
                            v-model="form.admin"
                            :items="[{value: true, text: 'Sim'}, {value: false, text: 'Não'}]"
                            label="Administrador"
                            class="mt-3"
                            outlined
                            dense
                            hide-details
                        />
                        <v-select
                            v-model="form.usuario_impressao"
                            :items="users"
                            :item-text="e => `${e.id} - ${e.name}`"
                            item-value="id"
                            clearable
                            hint="Todas as impressões serão direcionada para o usuário selecionado"
                            persistent-hint
                            label="Terminal de Impressão"
                            class="mt-3"
                            hide-details
                            outlined
                            dense
                        />
<!--
                        <h3 class="title text-center mt-4">Quando o usuário poderá fazer login?</h3>

                        <v-radio-group
                            v-model="form.disponibilidade.sempre_disponivel"
                            row
                            hide-details
                            style="margin-top: 5px;"
                        >
                            <v-radio
                                label="Sempre disponível"
                                :value="true"
                            />
                            <v-radio
                                label="Dias e horários específicos"
                                :value="false"
                            />
                        </v-radio-group>

                        <div v-if="!form.disponibilidade.sempre_disponivel" class="mt-4">
                            <v-row align="center" v-for="d in form.disponibilidade.programacao" :key="d.diaSemana">
                                <v-switch
                                    dense
                                    inset
                                    hide-details
                                    class="mr-2"
                                    style="width: 95px;"
                                    v-model="d.aberto"
                                    :label="d.diaSemana.substr(0, 3)"
                                />
                                <v-text-field
                                    v-model="d.horario"
                                    hide-details
                                    style="max-width: 105px; border: 1px solid #ddd; border-radius: 5px; padding-left: 5px;"
                                    v-mask="['##:## - ##:##']"
                                    placeholder="00:00 - 00:00"
                                    :disabled="!d.aberto"
                                />
                                <v-btn
                                    icon
                                    small
                                    color="primary"
                                    v-if="!d.segundoHorarioAtivo"
                                    @click="toogleSegundoHorario(d.diaSemana)"
                                >
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                                <v-text-field
                                    v-else
                                    v-model="d.segundoHorario"
                                    hide-details
                                    style="max-width: 130px; border: 1px solid #ddd; border-radius: 5px; padding-left: 5px;"
                                    v-mask="['##:## - ##:##']"
                                    placeholder="00:00 - 00:00"
                                    :disabled="!d.aberto"
                                >
                                    <template v-slot:append-outer>
                                        <v-btn icon x-small @click="toogleSegundoHorario(d.diaSemana)">
                                            <v-icon color="red">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                </v-text-field>
                            </v-row>
                        </div> -->

                        <!-- <v-divider class="mt-4"/> -->

                        <!-- <v-btn v-if="form.online" class="mt-4" color="error">Desconectar</v-btn> -->
                    </v-card-text>
                </v-card>
                <v-footer fixed class="pa-4">
                    <v-btn link large text color="warning" to="/colaboradores">Cancelar</v-btn>
                    <v-spacer />
                    <v-btn color="success" large @click="salvar" :loading="loading">
                        <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
                    </v-btn>
                </v-footer>
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';

export default {
    name: 'Colaborador',

    components: { BaseContainer },

    data: () => ({
        loading: false,
        form: {
            id: '',
            name: '',
            email: '',
            nova_senha: '',
            repetir_senha: '',
            admin: '',
            // disponibilidade: {
            //     sempre_disponivel: true,
            //     programacao: [
            //         {isoWeekday: 1, diaSemana: 'SEGUNDA', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
            //         {isoWeekday: 2, diaSemana: 'TERCA', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
            //         {isoWeekday: 3, diaSemana: 'QUARTA', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
            //         {isoWeekday: 4, diaSemana: 'QUINTA', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
            //         {isoWeekday: 5, diaSemana: 'SEXTA', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
            //         {isoWeekday: 6, diaSemana: 'SÁBADO', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
            //         {isoWeekday: 7, diaSemana: 'DOMINGO', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
            //     ],
            // },
        },
        users: [],
        showPassword: false,
        showPasswordConfirm: false,
    }),

    async mounted() {
        await this.fillUsers();

        let dados = this.$store.getters.getData;

        if (dados?.id) {
            this.form = dados;
            this.$store.commit('setData', {});
        }
    },

    methods: {
        async fillUsers() {
            await this.$http.get('usuarios').then(resp => {
                this.users = resp.data.data;
            });
        },

        salvar() {
            const id = this.form.id;

            if (!id && !this.form.nova_senha) {
                this.notify('Informe uma senha', 'warning');
                return;
            }

            if (this.form.nova_senha && this.form.nova_senha != this.form.repetir_senha) {
                this.notify('Senhas não conferem', 'warning');
                return;
            }

            if (id) {
                this.loading = true;
                this.$http.put(`usuarios/${id}`, this.form).then(() => {
                    this.$router.push('/colaboradores');
                    this.notify('Atualizado com sucesso!');
                }).catch(() => {
                    this.notify('Verifique sua conexão com a internet', 'warning');
                }).finally(() => (this.loading = false));
                return;
            }

            this.loading = true;
            this.$http.post('usuarios', this.form).then(() => {
                this.$router.push('/colaboradores');
                this.notify('Salvo com sucesso!');
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.loading = false));
        },

        toggleShowPassword(field) {
            this[field] = !this[field];
            this.$forceUpdate();
        },

        // toogleSegundoHorario(diaSemana) {
        //     const programacao = this.form.disponibilidade.programacao.find(f => f.diaSemana == diaSemana);
        //     programacao.segundoHorarioAtivo = !programacao.segundoHorarioAtivo;
        //     this.$forceUpdate();
        // },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}
</style>
