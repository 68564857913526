<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #e0e0e0;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title v-show="$vuetify.breakpoint.smAndUp">Combos</v-toolbar-title>
                    <v-spacer/>
                    <v-text-field
                        dense
                        clearable
                        outlined
                        single-line
                        hide-details
                        v-model="search"
                        label="Pesquise.."
                        append-icon="mdi-magnify"
                    />
                    <v-spacer/>
                    <v-tooltip v-if="isAdmin" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                @click="blockDraggable = !blockDraggable"
                                :loading="saving"
                                :color="blockDraggable ? '' : 'primary'"
                                v-bind="attrs"
                                v-on="on"
                                icon
                                small
                            >
                                <v-icon>
                                    {{ blockDraggable ? 'mdi-lock-outline' : 'mdi-content-save-outline' }}
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>{{ blockDraggable ? 'Desbloquear ordenação' : 'Salvar ordenação' }}</span>
                    </v-tooltip>
                    <v-spacer/>
                    <ButtonAddNew v-if="isAdmin" route="cadastro-combo" />
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text>
                        <table class="table-combos" style="width: 100%; color: #000;">
                            <draggable
                                v-model="dados"
                                :disabled="blockDraggable || search"
                                :style="`height: ${$vuetify.breakpoint.height - 190}px; overflow-x: auto;`"
                            >
                                <tr
                                    class="product-group"
                                    v-for="(combo, ia) in getData()"
                                    :key="ia"
                                    @click="editar(combo)"
                                    style="hover: #ddd;"
                                >
                                    <td class="mt-1">
                                        <v-btn icon style="cursor: move;" :disabled="blockDraggable">
                                            <v-icon>mdi-drag-vertical</v-icon>
                                        </v-btn>
                                    </td>
                                    <td class="ta-l mt-3 mb-3" style="width: 50%;">
                                        <span>{{ combo.descricao }}</span>
                                    </td>
                                    <td class="ta-l mt-3 mb-3">
                                        <span style="color: green;">R$ {{ combo.valor }}</span>
                                    </td>
                                    <td>
                                        <v-switch
                                            v-model="combo.status"
                                            inset
                                            hide-details
                                            dense
                                            true-value="ativo"
                                            false-value="inativo"
                                            @click.stop
                                            @change="onUpdateStatus(combo)"
                                            style="margin-top: 8px;"
                                        />
                                    </td>
                                    <td class="mt-1">
                                        <v-btn
                                            @click.stop="excluir(combo.id)"
                                            :disabled="!isAdmin"
                                            icon
                                            color="error"
                                        >
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </draggable>
                        </table>
                    </v-card-text>
                </v-card>
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import ButtonAddNew from '@/components/ButtonAddNew';
import draggable from 'vuedraggable';
import { mapState } from 'vuex';

export default {
    name: 'Combos',

    components: {
        BaseContainer,
        ButtonAddNew,
        draggable,
    },

    data: () => ({
        search: '',
        loading: true,
        dados: [],
        dialog: false,
        saving: false,
        blockDraggable: true,
        headers: [
            { text: 'Descrição', value: 'descricao', sortable: false },
            { text: 'Valor', value: 'valor', sortable: false },
            { text: 'Disponível', value: 'ativo', sortable: false },
            { text: '', value: 'action', sortable: false, align: 'end'},
        ],
    }),

    mounted() {
        this.consultar();
    },

    watch: {
        blockDraggable(v) {
            v && this.salvarOrdenacao();
        },
    },

    computed: {
        ...mapState([
            'usuario',
        ]),

        isAdmin() {
            return this.usuario.admin;
        },
    },

    methods: {
        getData() {
            const search = (this.search || '').toLowerCase();
            return this.dados.filter(e => e.descricao.toLowerCase().includes(search));
        },

        consultar() {
            this.loading = true;
            this.$http.get('combos').then(resp => {
                this.dados = resp.data.data;
                this.loading = false;
            })
            .catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
                this.loading = false;
            });
        },

        editar(row) {
            if (!this.isAdmin) {
                return;
            }
            this.$store.commit('setData', row);
            this.$router.push('/cadastro-combo');
        },

        onUpdateStatus(combo) {
            this.$http.put(`combos/update-status/${combo.id}`, combo).then(() => {
                this.notify('Atualizado com sucesso!');
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            });
        },

        async excluir(id) {
            if (await this.$root.$confirm('Confirmação', 'Deseja realmente excluir?', { color: 'red darken-1' })) {
                this.$http.delete(`combos/${id}`).then(() => {
                    this.notify('Excluído com sucesso!');
                    this.consultar();
                })
                .catch(() => {
                    this.notify('Verifique sua conexão com a internet', 'warning');
                });
            }
        },

        saveOrder(event) {
            const movedItem = this.dados.splice(event.oldIndex, 1)[0];
            this.dados.splice(event.newIndex, 0, movedItem);

            this.dados.forEach((c, index) => (c.ordem = index + 1));
        },

        salvarOrdenacao() {
            const combos = this.dados.map((c, index) => ({
                id: c.id,
                ordem: index + 1
            }));

            this.saving = true;
            this.$http.post(`combos/update-ordem`, { combos }).then(() => {
                this.notify('Atualizado com sucesso!');
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.saving = false));
        },
    },
}
</script>

<style scoped>
.table-combos tr:hover {
    background: #ddd;
}

.product-group {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    border-top: 1px solid #ddd;
}

.ta-l {
    text-align: left;
}
</style>