<template>
    <div>
        <v-data-table
            fixed-header
            class="elevation-0"
            :headers="headers"
            :items="value"
            :mobileBreakpoint="0"
            :disable-pagination="true"
            :hide-default-footer="true"
            :height="300"
            style="border: 1px solid #999;"
        >
            <template v-slot:item="{ item }">
                <tr>
                    <td style="padding: 10px 0 10px 10px;">
                        <div>
                            <span>
                                <b>{{ item.descricao + ' ' }}</b>
                            </span>
                            <span>
                                <i style="color: red;">{{ (item.observacao || '').substr(0, 48) }}</i>
                            </span>
                        </div>
                        <div>
                            <StepsList :item="item"/>

                            <div v-for="(product, i) in item.produtos" :key="i">
                                <v-row class="ml-1">
                                    <v-col cols="12">
                                        <b>{{ product.descricao }}</b>
                                    </v-col>
                                </v-row>

                                <StepsList
                                    :item="product"
                                    :tab="8"
                                    :combo="true"
                                />

                                <v-divider style="margin: 5px;"/>
                            </div>
                        </div>
                        <div style="display: flex; align-items: center;">
                            <v-btn
                                small
                                icon
                                color="black"
                                @click.stop="$emit('removerQtd', item.index)"
                            >
                                <v-icon large>mdi-minus-box</v-icon>
                            </v-btn>
                            <span class="product-qtd mx-1">
                                <b>{{ item.quantidade }} Und</b>
                            </span>
                            <v-btn
                                small
                                icon
                                color="black"
                                @click.stop="$emit('adicionarQtd', item.index)"
                            >
                                <v-icon large>mdi-plus-box</v-icon>
                            </v-btn>
                        </div>
                    </td>
                    <td style="text-align: center; padding-top: 10px; min-width: 105px; font-weight: bold; vertical-align: top;">
                        <template v-if="!item.editarValor">
                            <span>R$</span>
                            <span>{{ formatPrice(+item.valor || 0) }}</span>
                            <!-- Não é possivel editar valor de combo pois ocorre erro na emissão de nota -->
                            <!-- Quando combo, o valor editavel deveria ser por item do combo -->
                            <v-icon
                                v-if="!item.hasOwnProperty('produtos')"
                                @click="enableValueEdit(item)"
                                small
                            >mdi-pencil</v-icon>
                        </template>
                        <template v-else>
                            <span style="margin-right: -20px;">R$</span>
                            <input
                                type="text"
                                v-model="valorEditavel"
                                v-mask="['#.##','##.##','###.##']"
                                style="width: 68px; border: solid 1px #585858; border-radius: 4px; padding-left: 20px;"
                            >
                            <v-btn
                                @click="atualizarValor(item.index)"
                                small
                                icon
                                color="primary"
                            >
                                <v-icon small>mdi-content-save</v-icon>
                            </v-btn>
                        </template>
                        <br>
                        <v-btn
                            outlined
                            small
                            depressed
                            tile
                            color="primary"
                            @click.stop="abrirObservacao(item, item.observacao)"
                        >
                            Obs
                        </v-btn>
                    </td>
                    <td style="vertical-align: top; padding-top: 10px;">
                        <span>
                            <b>R${{ formatPrice(item.total) }}</b>
                        </span>
                        <br>
                    </td>
                </tr>
            </template>
        </v-data-table>

        <DialogObservacaoProduto
            v-model="observacao"
            :visible="dialogObservacaoProdutoVisible"
            @save="onSave"
            @close="dialogObservacaoProdutoVisible = false"
        />
    </div>
</template>

<script>
import DialogObservacaoProduto from './DialogObservacaoProduto';
import StepsList from '../StepsList';

export default {
    name: 'DataTablePedidoProdutos',

    components: {
        DialogObservacaoProduto,
        StepsList
    },

    props: ['value'],

    data: () => ({
        headers: [
            {text: 'Item', value: 'item', sortable: false, align: 'start'}, // start center end
            {text: 'Valor', value: 'valor', sortable: false, align: 'center'},
            {text: 'Total', value: 'total', sortable: false, align: 'center'},
        ],
        comandaTotalizador: 0,
        dialogObservacaoProdutoVisible: false,
        observacao: {},
        valorEditavel: 0,
    }),

    computed: {
        getPedidoProdutos() {
            const produtos = JSON.parse(JSON.stringify(this.value));
            produtos.forEach((p, index) => {
                p.index = index;
            });
            return produtos;
        },
    },

    methods: {
        abrirObservacao(item, text = '') {
            this.observacao = { item, text };

            this.dialogObservacaoProdutoVisible = true;
        },

        onSave(observacao) {
            this.$emit('updateObs', observacao);

            this.dialogObservacaoProdutoVisible = false;
        },

        enableValueEdit(item) {
            this.valorEditavel = item.valor;

            this.$emit('enableValueEdit', item.index);
        },

        atualizarValor(index) {
            this.$emit('atualizarValor', { index, valor: this.valorEditavel })
        },
    },
}
</script>
