<template>
    <v-menu
        v-model="open"
        offset-y
        left
        :close-on-content-click="false"
        transition="slide-y-transition"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                icon
            >
                <v-icon
                    :class="{ 'shake-animation': notificacoesNaoLidasCount > 0 }"
                >
                    mdi-bell-outline
                </v-icon>
                <v-badge
                    v-if="notificacoesNaoLidasCount > 0"
                    color="blue"
                    :content="notificacoesNaoLidasCount"
                    offset-x="5"
                    offset-y="-5"
                />
            </v-btn>
        </template>
        <v-card class="root">
            <v-card-title>
                <span>Notificações</span>
                <v-spacer/>
                <v-btn icon @click="open = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-list
                three-line
                class="notification-container pb-2"
                @scroll.native="onScroll"
            >
                <template v-if="dados.length === 0 && !loadingMore">
                    <v-list-item>
                        <v-list-item-content class="text-center">
                            <v-list-item-title class="grey--text text--darken-1">
                                Nenhuma notificação disponível.
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <template v-if="dados.length">
                    <div
                        v-for="(item, i) in dados"
                        :key="i"
                        class="highlight-on-hover notification-item"
                    >
                        <v-list-item @click="onNotificationClick(item)">
                            <v-list-item-avatar>
                                <v-icon color="blue darken-3">mdi-{{ item.icon }}</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title class="font-weight-bold">
                                    {{ item.title }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <span v-html="item.text"/>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action class="d-flex flex-column align-center">
                                <v-list-item-action-text class="grey--text text--darken-1">
                                    {{ timeAgo(item.time, 'DD/MM') }}
                                </v-list-item-action-text>
                                <v-icon v-if="!item.read" color="blue darken-3" small>
                                    mdi-circle-medium
                                </v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </div>
                </template>
                <template v-if="loadingMore">
                    <v-skeleton-loader v-for="i in 4" :key="i + Math.random()"
                        type="button"
                        class="ma-3"
                    />
                </template>
                <template v-if="!loadingMore && page >= totalPages && dados.length > 5">
                    <v-list-item>
                        <v-list-item-content class="text-center">
                            <v-list-item-title class="grey--text text--darken-1">
                                🎉 Todas as notificações foram exibidas.
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </v-card>

        <v-dialog v-model="dialogVisible" persistent max-width="500px">
            <v-card>
                <v-card-title
                    class="dialog-title justify-space-between align-center"
                >
                    <span
                        v-html="notificacao.title"
                        class="dialog-title-text"
                    />
                    <v-btn icon @click="dialogVisible = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>


                <v-divider/>

                <v-card-text class="dialog-text">
                    <span
                        v-html="notificacao.text"
                    />
                </v-card-text>

                <v-divider/>

                <v-card-actions class="justify-end px-4 pb-4">
                    <v-btn text color="primary" @click="dialogVisible = false">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-menu>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
    name: 'Notifications',

    data: () => ({
        dados: [],
        loading: false,
        open: false,
        showBadge: true,
        loadingMore: false,
        page: 1,
        totalPages: 2,
        notificacao: {},
        dialogVisible: false,
    }),

    watch: {
        open(v) {
            if (v) {
                this.showBadge = false;
                this.page = 1;
                this.dados = [];
                this.consultar();
            }
        },
    },

    computed: {
        ...mapState([
            'notificacoesNaoLidasCount',
        ]),
    },

    methods: {
        ...mapMutations({
            setNotificacoesNaoLidasCount: 'setNotificacoesNaoLidasCount',
        }),

        consultar() {
            this.loadingMore = true;
            const data = { page: this.page };
            this.$http.post('notificacoes/get-by-user', data)
                .then(resp => {
                    if (resp.data.type == 'warning') {
                        this.notify(resp.data.msg, 'warning');
                        return;
                    }

                    this.setNotificacoesNaoLidasCount(0);

                    const newData = resp.data.data.map(e => ({
                        ...e,
                        title: e.notificacao.title,
                        text: e.notificacao.text,
                        icon: e.notificacao.icon,
                        time: e.notificacao.created_at,
                    }));

                    this.dados = [...this.dados, ...newData];

                    this.totalPages = resp.data.meta.last_page;
                })
                .catch(() => this.notify('Não foi possivel atualizar', 'warning'))
                .finally(() => (this.loadingMore = false));
        },

        onNotificationClick(item) {
            item.read = true;
            this.notificacao = item.notificacao;
            this.dialogVisible = true;
        },

        onScroll(event) {
            const bottomOfList = Math.abs(event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight) < 2;

            if (bottomOfList && !this.loadingMore && this.page < this.totalPages) {
                this.page++;
                this.loadingMore = true;
                this.consultar();
            }
        },
    },
}
</script>

<style scoped lang="scss">
.highlight-on-hover {
    transition: background-color 0.1s ease;
}

.highlight-on-hover:hover {
    transform: scale(1.02);
}

.root {
    width: 600px !important;
}

.notification-container {
    padding: 0;
    max-height: 400px;
    overflow-x: auto;
}

.notification-item {
    background: #fff;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    margin: 8px 10px;
    cursor: pointer;
}

.v-list {
    scrollbar-width: none; /* Firefox */
}

.v-list::-webkit-scrollbar {
    width: 0; /* Chrome, Safari, Opera */
    height: 0;
}

::v-deep .v-skeleton-loader__button {
    border-radius: 10px;
    width: 100%;
    height: 80px;
}

@keyframes shake {
    0% { transform: rotate(0); }
    1% { transform: rotate(30deg); }
    3% { transform: rotate(-28deg); }
    5% { transform: rotate(34deg); }
    7% { transform: rotate(-32deg); }
    9% { transform: rotate(30deg); }
    11% { transform: rotate(-28deg); }
    13% { transform: rotate(26deg); }
    15% { transform: rotate(-24deg); }
    17% { transform: rotate(22deg); }
    19% { transform: rotate(-20deg); }
    21% { transform: rotate(18deg); }
    23% { transform: rotate(-16deg); }
    25% { transform: rotate(14deg); }
    27% { transform: rotate(-12deg); }
    29% { transform: rotate(10deg); }
    31% { transform: rotate(-8deg); }
    33% { transform: rotate(6deg); }
    35% { transform: rotate(-4deg); }
    37% { transform: rotate(2deg); }
    39% { transform: rotate(-1deg); }
    41% { transform: rotate(1deg); }
    43% { transform: rotate(0); }
    100% { transform: rotate(0); }
}

.shake-animation {
    animation: shake 4s .7s ease-in-out infinite;
}

.dialog-title {
    background-color: #f5f5f5;
    white-space: nowrap;
}

.dialog-title-text {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dialog-text {
    padding: 16px;
    margin-top: 16px;
    font-size: 16px;
    line-height: 1.5;
    color: #666;
    word-wrap: break-word;
    overflow-wrap: break-word;
}
</style>
