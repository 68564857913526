<template>
    <div class="flex-file-container">
        <div>
            <label
                :for="`file-${key}`"
                :class="`btn-file ma-2 ${disabled ? 'disabled' : ''}`"
            >
                <v-icon v-if="icon" color="white">mdi-{{ icon }}</v-icon>
                {{ label || 'Selecionar' }}
            </label>
            <input
                :disabled="disabled"
                type="file"
                :accept="accept"
                :ref="`file-${key}`"
                :id="`file-${key}`"
                :name="`file-${key}`"
                @input="uploader"
            >
        </div>
        <div>
            <v-img
                v-if="showImage"
                :src="getUrl()"
                class="ma-1"
                max-width="160"
                style="border-radius: 2px;"
                contain
            />
            <div v-else-if="showVideo">
                <video controls width="180">
                    <source :src="getUrl()" type="video/mp4">
                </video>
            </div>
            <div v-else-if="showAudio">
                <audio controls width="220">
                    <source :src="getUrl()" type="audio/ogg">
                </audio >
            </div>
            <div v-else class="caption" style="width: 100px;">
                {{ getFileName() }}
            </div>
            <v-btn
                v-if="getUrl()"
                @click.native="clear"
                :disabled="disabled"
                color="red"
                class="white--text mb-2"
                small
            >
                Excluir
            </v-btn>
        </div>
    </div>
</template>

<script>
import { convertToPng } from '@/utils/image';

export default {
    name: 'FileInput',

    props: [
        'value',
        'accept',
        'validateType',
        'label',
        'icon',
        'disabled',
    ],

    data: () => ({
        file: null,
        key: null,
    }),

    computed: {
        showImage() {
            return this.getUrl() && this.getFileType() === 'image';
        },

        showVideo() {
            return this.getUrl() && this.getFileType() === 'video';
        },

        showAudio() {
            return this.getUrl() && this.getFileType() === 'audio';
        },
    },

    watch: {
        value(v) {
            this.setFile(v);
        },
    },

    mounted() {
        this.setFile(this.value);

        this.key = this.randomKeyGenerate();
    },

    methods: {
        setFile(value) {
            if (!value?.url && !value?.length) {
                this.file = [];
                return;
            }
            value?.length && (this.file = value.pop());
            value?.url && (this.file = value);
        },

        clear() {
            this.file = [];
            this.$emit('input', null);
        },

        getUrl() {
            if (!this.file?.url) {
                return '';
            }

            const url = this.file.tmp
                ? `${this.$urlAPI}/files/tmp/${this.file.url}`
                : `${this.$urlAPI}/storage/${this.file.url}`;

            return url;
        },

        getFileName() {
            if (!this.file?.fileName) {
                return '';
            }

            return (this.file.fileName.length > 30)
                ? this.file.fileName.substring(0, 30) + '...'
                : this.file.fileName;
        },

        async uploader(e) {
            const files = e.target.files;
            try {
                const urlUpload = `${this.$urlAPI}/files/upload`;
                const file = files[0];
                const { type } = file;
                const extension = type.split('/')[1];
                const acceptFileTypes = this.validateType.split(',').map(type => type.trim());
                const fileType = this.getFileType(file);

                const convertedFile = fileType === 'image' && extension !== 'png'
                    ? await convertToPng(file)
                    : file;

                if (acceptFileTypes.length && !acceptFileTypes.includes(extension)) {
                    this.notify(`Formato do arquivo inválido (${extension})`, 'warning');
                    return;
                }

                const body = new FormData();
                body.append('file', convertedFile);
                const resp = await fetch(urlUpload, {
                    method: 'POST',
                    headers: {
                        authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    },
                    body,
                });

                const name = convertedFile.name.split('.');
                name.pop();

                const { path: url } = await resp.json();

                this.$emit('input', {
                    descricao: name.join('.'),
                    file: convertedFile,
                    fileName: convertedFile.name,
                    tmp: true,
                    url,
                });
            } catch (error) {
                this.notify(error, 'warning');
            }
        },

        randomKeyGenerate() {
            let caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let key = '';
            for (let i = 0; i < 8; i++) {
                key += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
            }
            return key;
        },

        getFileType(file = this.file) {
            const fileName = file?.fileName || file?.name || '';
            const extension = fileName.split('.').pop().toLowerCase();

            if (['png', 'jpg', 'jpeg', 'gif'].includes(extension)) {
                return 'image';
            } else if (['mp4', 'webm'].includes(extension)) {
                return 'video';
            } else if (['mp3', 'ogg', 'wav'].includes(extension)) {
                return 'audio';
            } else if (['xml'].includes(extension)) {
                return 'document';
            } else {
                return null;
            }
        },
    },
}
</script>

<style scoped>
input[type="file"] {
    display: none;
}

label {
    padding: 10px 10px;
    /* width: 130px; */
    height: 44px;
    background-color: #333;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    display: block;
    margin-top: 25px;
    cursor: pointer;
}

.disabled {
    background-color: #9999;
}

.btn-file {
    border-radius: 5px;
}

.flex-file-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}
</style>
