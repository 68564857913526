<template>
    <v-dialog v-model="visible" persistent max-width="700px">
        <v-card light>
            <v-card-title class="headline justify-center">
                <v-spacer />
                <span>
                    Assinatura emitida com sucesso!
                </span>
                <v-spacer />
                <v-btn @click="$emit('close')" fab right depressed color="white">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="text-center">
                <div v-if="qrCode" class="pa-1">
                    <span style="font-size: 16px; color: #606060">
                        Faça o pagamento pelo Pix (aprovação em até 5 min)
                    </span>
                    <v-img class="mt-2 mx-auto" height="200" width="200" :src="qrCode"/>
                </div>
                <v-divider></v-divider>
                <div v-if="barCode" class="mt-4 pa-1">
                    <span style="font-size: 16px; color: #606060">
                        Ou pague pelo Códgio de Barras (aprovação em 1 dia útil )
                    </span>
                </div>
                    <img ref="barCode"/>
                <div v-if="boleto" large class="pa-1">
                    <v-btn color="#1fc625" class="white--text" @click="visualizarBoleto">
                        Visualizar Boleto
                    </v-btn>
                </div>
                <!-- <div v-if="link" class="pa-1">
                    Ou acesse o boleto
                    <a :href="link" target="_blank">Compartilhar</a>
                </div> -->
            </v-card-text>
            <!-- <v-card-actions>
                <v-btn color="green" class="white--text" @click="$emit('confirm')" block>Ok</v-btn>
            </v-card-actions> -->
        </v-card>
    </v-dialog>
</template>

<script>
import JsBarcode from 'jsbarcode';

export default {
    name: 'AssinaturaConcluida',

    props: {
        visible: {
            type: Boolean,
            default: false
        },
        value: {
            type: Object,
            default: () => {}
        },
    },

    data: () => ({
    }),

    computed: {
        qrCode() {
            return this.value?.data?.pix.qrcode_image;
        },

        barCode() {
            return this.value?.data?.barcode
                .replace(/[^0-9]/g, '')
                .replace(/^(\d{4})(\d{5})\d{1}(\d{10})\d{1}(\d{10})\d{1}(\d{15})$/, '$1$5$2$3$4');
        },

        boleto() {
            return this.value?.data?.pdf.charge;
        },

        link() {
            return '';
            // return this.value?.data?.payment.data.link;
        },
    },

    watch: {
        value(v) {
            v?.data?.barcode && setTimeout(() => JsBarcode(this.$refs.barCode, this.barCode, {
                width: 1,
                height: 70,
                flat: true,
                format: 'itf',
            }), 500);
        }
    },

    mounted() {
        this.value?.data?.barcode && JsBarcode(this.$refs.barCode, this.barCode, {
            width: 1,
            height: 70,
            flat: true,
            format: 'itf',
        });
    },

    methods: {
        visualizarBoleto() {
            this.boleto && window.open(this.boleto, '_blank');
        }
    }
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}
</style>
