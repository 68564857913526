<template>
   <v-navigation-drawer
        v-model="slider"
        app
        right
        hide-overlay
        permanent
        fixed
        clipped
        width="400"
        style="padding: 12px 12px 100px 12px;"
    >
        <div style="text-align: center;">
            <h3>Comanda Rápida</h3>
        </div>
        <CustomerSearchAutocomplete
            ref="cliente"
            v-model="cliente"
            @update="onClienteUpdate"
            @reset="cliente = null"
        />
        <v-btn
            @click="adicionarProdutos"
            color="primary"
            class="mt-4"
            block
        >
            <v-icon>mdi-plus</v-icon>&nbsp;
            <span>Adicionar Produtos</span>
        </v-btn>

        <DatatablePedidoProdutos
            v-model="pedido.produtos"
            @updateObs="onUpdateObs"
            @removerQtd="onRemoverQtd"
            @adicionarQtd="onAdicionarQtd"
            @atualizarValor="onAtualizarValor"
            @enableValueEdit="onEnableValueEdit"
        />

        <DialogAdicionarProdutos
            v-model="pedido"
            :visible="dialogAdicionarProdutosVisible"
            @close="closeDialogAdicionarProdutos"
        />

        <v-btn-toggle
            v-model="pedido.entrega"
            rounded
            :style="cssVars"
            class="mt-2"
        >
            <v-btn value="2" style="font-size: 10px; height: 60px; width: 30%;">
                <v-col cols="12">
                    <v-row
                        width="100%"
                        style="place-content: center;"
                    >
                        <v-icon :color="isDelivery ? '#fff' : '#000'">
                            mdi-bike-fast
                        </v-icon>
                    </v-row>
                    <v-row width="100%" style="place-content: center">
                        <span><b>Entregar</b></span>
                    </v-row>
                </v-col>
            </v-btn>

            <v-btn value="1" style="font-size: 10px; height: 60px; width: 30%;">
                <v-col cols="12">
                    <v-row
                        width="100%"
                        style="place-content: center;"
                    >
                        <v-icon :color="isRetirada ? '#fff' : '#000'">
                            mdi-walk
                        </v-icon>
                    </v-row>
                    <v-row width="100%" style="place-content: center">
                        <span><b>Retirar<br/>no Balcão</b></span>
                    </v-row>
                </v-col>
            </v-btn>

            <v-btn value="3" style="font-size: 10px; height: 60px; width: 30%;">
                <v-col cols="12">
                    <v-row
                        width="100%"
                        style="place-content: center;"
                    >
                        <v-icon :color="isLocal ? '#fff' : '#000'">
                            mdi-table-furniture
                        </v-icon>
                    </v-row>
                    <v-row width="100%" style="place-content: center">
                        <span><b>Mesa</b></span>
                    </v-row>
                </v-col>
            </v-btn>
        </v-btn-toggle>

        <v-row
            v-if="permitirAgendamento"
            style="margin-top: -10px; margin-bottom: -10px;"
        >
            <v-col class="mt-2">
                <div style="color: #787878">Quando será a entrega / retirada?</div>
                <v-radio-group
                    v-model="pedido.agendado"
                    row
                    hide-details
                    style="margin-top: 5px;"
                >
                    <v-radio
                        label="Hoje"
                        :value="false"
                    />
                    <v-radio
                        label="Agendar"
                        :value="true"
                    />
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row v-if="pedido.agendado">
            <v-col class="py-1">
                <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :value="agendamentoFormatted"
                            label="Data do agendamento"
                            prepend-inner-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            hide-details
                            outlined
                            dense
                        />
                    </template>
                    <v-date-picker
                        v-model="agendado_data"
                        no-title
                        @input="onInputDate"
                        locale="pt-br"
                        :allowed-dates="allowedDates"
                        dense
                        hide-details
                    />
                </v-menu>
            </v-col>
            <v-col v-if="agendamentoExibirHorario" class="py-1">
                <v-select
                    v-model="agendado_hora"
                    label="Horário"
                    :items="horarios"
                    :disabled="!agendado_data"
                    prepend-inner-icon="mdi-clock-outline"
                    hide-details
                    dense
                    outlined
                />
            </v-col>
        </v-row>

        <v-row v-if="isLocal" class="mt-2">
            <v-col>
                <v-select
                    v-model="pedido.mesa"
                    :items="mesas"
                    label="Nº Mesa"
                    hide-details
                    outlined
                    dense
                />
            </v-col>
            <v-col>
                 <v-select
                    v-model="pedido.pessoas"
                    :items="pessoas"
                    label="Qtde. Pessoas"
                    hide-details
                    outlined
                    dense
                />
            </v-col>
        </v-row>

        <v-text-field
            v-if="isDelivery"
            v-model="pedido.valor_frete"
            label="Taxa de Entrega"
            class="mt-4"
            prefix="R$"
            v-mask="['#', '##', '#.##', '##.##', '###.##']"
            dense
            outlined
            hide-details
        />

        <v-row class="mt-1">
            <v-col class="pagamento">
                <v-select
                    @change="onChangeFormaPgto"
                    v-model="pedido.forma_pagamento"
                    :items="formasPagamento"
                    label="Forma de Pagamento"
                    item-value="id"
                    item-text="descricao"
                    outlined
                    dense
                    hide-details
                />
            </v-col>
            <v-col v-if="isDinheiro" class="pagamento">
                <v-text-field
                    v-model="pedido.troco"
                    label="Troco para"
                    prefix="R$"
                    v-mask="['#', '##', '#.##', '##.##', '###.##']"
                    dense
                    outlined
                    hide-details
                />
                <div v-if="pedido.troco">
                    <span class="caption">
                        <b>VLR. TROCO:</b> R$ {{ formatPrice(pedido.troco - total.final) }}
                    </span>
                </div>
            </v-col>
        </v-row>

        <v-textarea
            v-model="pedido.observacao"
            label="Observação"
            class="mt-6"
            rows="2"
            clear-icon="mdi-backspace-outline"
            clearable
            hide-details
            dense
            outlined
        />

        <v-select
            v-if="isDelivery"
            v-model="pedido.entregador_id"
            :items="entregadores"
            label="Entregador"
            item-value="id"
            item-text="nome"
            class="mt-3"
            hide-details
            outlined
            clearable
            dense
        />

        <v-footer fixed class="pa-4">
            <div>
                <div class="caption">
                    TOTAL PEDIDO
                </div>
                <div style="font-size: 20px; font-weight: bold;">
                    R$ {{ formatPrice(total.final) }}
                </div>
            </div>
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-information</v-icon>
                    </v-btn>
                </template>
                <v-list class="pa-4">
                    <div><b>Taxa Entrega:</b> R${{ formatPrice(total.taxaEntrega) }}</div>
                    <div><b>Taxa Forma Pgto:</b> R${{ formatPrice(total.taxaFormaPgto) }}</div>
                    <div><b>Total Produtos:</b> R${{ formatPrice(total.produtos) }}</div>
                    <div><b>Total Final:</b> R${{ formatPrice(total.final) }}</div>
                </v-list>
            </v-menu>

            <v-spacer />

            <v-btn @click="novoPedido" text small color="warning">
                Cancelar
            </v-btn>

            <v-spacer />

            <v-btn @click="salvar" color="success" large :loading="loading">
                <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
            </v-btn>
        </v-footer>
    </v-navigation-drawer>
</template>

<script>
import DatatablePedidoProdutos from './DatatablePedidoProdutos';
import DialogAdicionarProdutos from './DialogAdicionarProdutos';
import CustomerSearchAutocomplete from './CustomerSearchAutocomplete';
import { subtotal } from '@/utils/calc';
import { mapState } from 'vuex';

export default {
    name: 'PedidoSlider',

    components: {
        DatatablePedidoProdutos,
        DialogAdicionarProdutos,
        CustomerSearchAutocomplete,
    },

    data: () => ({
        slider: true,
        loading: false,
        produtos: [],
        dialogAdicionarProdutosVisible: false,
        pedido: {
            id: 'auto',
            cliente_id: null,
            mesa: null,
            pronto: false,
            pessoas: 1,
            entrega: null,
            observacao: null,
            status: 'confirmado',
            produtos: [],
            total: 0,
            agendado: false,
        },
        cliente: null,
        mesas: Array.from({length: 50}, (_, i) => i + 1),
        pessoas: Array.from({length: 25}, (_, i) => i + 1),
        menu: null,
        agendado_hora: '',
        agendado_data: '',
    }),

    computed: {
        ...mapState([
            'formasPagamento',
            'entregadores',
            'configuracoes',
        ]),

        cssVars() {
            return {
                '--bg-color': '#3874d0',
                '--color': '#fff',
                'width': '100%',
            };
        },

        isRetirada() {
            return this.pedido.entrega == 1;
        },

        isDelivery() {
            return this.pedido.entrega == 2;
        },

        isLocal() {
            return this.pedido.entrega == 3;
        },

        isDinheiro() {
            return this.pedido.forma_pagamento === 1;
        },

        permitirAgendamento() {
            return this.configuracoes.agendamento?.ativo;
        },

        agendamentoExibirHorario() {
            return !this.configuracoes.agendamento?.ocultarHorario;
        },

        horarios() {
            return this.gerarHorarios();
        },

        agendamentoFormatted() {
            return this.agendado_data
                ? this.moment(this.agendado_data).format('DD/MM/YYYY')
                : '';
        },

        total() {
            const total = {
                taxaFormaPgto: 0,
                taxaEntrega: 0,
                produtos: 0,
                final: 0,
            };

            total.taxaEntrega = this.isDelivery ? +(this.pedido.valor_frete || 0) : 0;

            total.produtos = this.pedido.produtos.reduce((a, b) => a + +subtotal(b), 0) || 0;

            if (this.pedido.forma_pagamento) {
                let totPed = total.produtos + total.taxaEntrega;

                const formaPgto = this.formasPagamento.find(e => e.id == this.pedido.forma_pagamento);

                let taxa = 0;

                if (formaPgto) {
                    const acrescimoPercentual = +formaPgto.acrescimo_percentual;
                    const acrescimoValor = +formaPgto.acrescimo_valor;

                    taxa += Math.round((totPed * acrescimoPercentual / 100) * 100) / 100;
                    taxa += acrescimoValor;
                }

                total.taxaFormaPgto = taxa;
            }

            total.final = total.produtos + total.taxaEntrega + total.taxaFormaPgto;

            return total;
        },
    },

    methods: {
        adicionarProdutos() {
            this.dialogAdicionarProdutosVisible = true;
        },

        closeDialogAdicionarProdutos() {
            this.dialogAdicionarProdutosVisible = false;
            this.atualizarTotais();
        },

        onRemoverQtd(index) {
            this.pedido.produtos = this.pedido.produtos.filter(produto => {
                if (produto.index == index && produto.quantidade > 0) {
                    produto.quantidade--;
                }

                return produto.quantidade > 0;
            });

            this.atualizarTotais();
        },

        onAdicionarQtd(index) {
            (this.pedido.produtos.find(produto => produto.index == index)).quantidade++;

            this.atualizarTotais();
        },

        onAtualizarValor({ index, valor }) {
            const produto = this.pedido.produtos.find(i => i.index == index);
            produto.valor = valor;

            const produtos = this.pedido.produtos.filter(i => {
                i.editarValor = false;
                return i;
            });

            this.pedido.produtos = produtos;

            this.atualizarTotais();
        },

        // onChangeEntrega() {
        //     if (!this.isDelivery) {
        //         return;
        //     }

        //     // if (!this.endereco.bairro) {
        //     //     this.dialogClienteVisible = true;
        //     //     this.notify('Informe o endereço', 'warning');
        //     // }
        // },

        onUpdateObs(observacao) {
            const { index } = observacao.item;

            const produtos = this.pedido.produtos.filter(i => {
                (i.index == index) && (i.observacao = observacao.text);
                return i;
            });

            this.pedido.produtos = produtos;
        },

        atualizarTotais() {
            this.pedido.produtos.forEach(i => {
                i.total = subtotal(i);
            });

            this.pedido.total = this.total.final;
        },

        async salvar() {
            const invalid = !this.validate();

            if (invalid) {
                return;
            }

            // this.$root.$emit('loading', true);
            this.loading = true;
            if (this.pedido.id == 'auto') {
                const ddmmyyyy = this.moment(this.agendado_data).format('YYYY-MM-DD');
                const hhmm = this.agendado_hora;
                const agendado_datahora = this.pedido.agendado ? `${ddmmyyyy} ${hhmm}` : null;

                await this.$http.post('pedidos', { ...this.pedido, ...{ agendado_datahora }}).then(resp => {
                    if (resp.data.type == 'warning') {
                        this.notify(resp.data.msg, 'warning');
                        return;
                    }

                    this.notify('Salvo com sucesso!');
                    this.novoPedido();
                })
                .catch(() => this.notify('Não foi possivel salvar', 'warning'))
                .finally(() => (this.loading = false));
            }
        },

        validate() {
            if (!this.pedido.cliente_id) {
                this.notify('Informe o cliente', 'warning');
                setTimeout(() => this.$refs.cliente.$refs.input.focus(), 100);
                return false;
            }

            if (!this.pedido.entrega) {
                this.notify('Selecione a forma de entrega', 'warning');
                return false;
            }

            if (this.pedido.agendado || this.agendamentoObrigatorio) {
                if (!this.agendado_data) {
                    this.notify('Informe a data do agendamento', 'warning');
                    return false;
                }
                if (!this.agendado_hora) {
                    this.notify('Informe a hora do agendamento', 'warning');
                    return false;
                }
            }

            if (this.pedido.agendado && this.agendado_data && this.agendamentoExibirHorario && typeof this.agendado_hora != 'string') {
                this.notify('Informe a hora do agendamento', 'warning');
                return false;
            }

            return true;
        },

        async novoPedido(confirm = false) {
            if (confirm && !await this.$root.$confirm('Confirmação', 'Deseja limpar os campos e iniciar um novo pedido?', { color: 'red darken-1' })) {
                return;
            }

            this.pedido = {
                id: 'auto',
                cliente_id: null,
                mesa: null,
                pronto: false,
                pessoas: 1,
                entrega: null,
                observacao: null,
                status: 'confirmado',
                produtos: [],
                total: 0,
                agendado: false,
            };

            this.cliente = {};
            this.$refs.cliente.reset();
        },

        onClienteUpdate(cliente) {
            this.cliente = cliente;

            if (!cliente) {
                return;
            }

            const endereco = cliente.enderecos[0];

            this.pedido = {...this.pedido, ...{
                entrega: null,
                cliente_id: cliente.id,
                valor_frete: endereco?.valor_frete || 0,
            }};

            this.pedido.endereco = null;

            if (endereco) {
                this.pedido.endereco = {
                    municipio: endereco.municipio,
                    bairro: endereco.bairro,
                    rua: endereco.rua,
                    numero: endereco.numero,
                    referencia: endereco.referencia,
                    observacao: endereco.observacao,
                    coordenadas: endereco.coordenadas,
                    tipo_local: endereco.tipo_local,
                };

                if (endereco.tipo_local === 'apartamento') {
                    this.pedido.endereco.nome_edificio = endereco.nome_edificio;
                    this.pedido.endereco.numero_apartamento = endereco.numero_apartamento;
                }
            }
        },

        onEnableValueEdit(index) {
            const produtos = this.pedido.produtos.filter(i => {
                i.editarValor = i.index == index;
                return i;
            });

            this.pedido.produtos = produtos;
        },

        onChangeFormaPgto() {
            this.pedido.acrescimo = this.total.taxaFormaPgto;
        },

        onInputDate() {
            this.menu = false;
            this.agendado_hora = [];
        },

        allowedDates(val) {
            const dayOfWeek = this.moment(val).isoWeekday();
            const isHojeAdiante = this.moment(val).isSameOrAfter(this.moment().startOf('day'));
            const horarios = this.configuracoes?.agendamento.horarios;

            const currentDay = horarios.find(e => e.isoWeekday == dayOfWeek);

            return isHojeAdiante && currentDay.aberto;
        },

        obterHorarios(intervalo) {
            const horarios = [];
            const formato = 'HH:mm';
            const intervaloHorarioMinutos = this.configuracoes?.agendamento.intervaloHorarioMinutos || 30;

            const [horarioInicio, horarioFim] = intervalo.split(' - ');
            const horaInicio = this.moment(horarioInicio, formato);
            const horaFim = this.moment(horarioFim, formato);
            // const horaAtual = this.moment();

            // const tempoEntrega = this.configuracoes.tempo_entrega.split(' - ')[1];
            // const tempoRetirada = this.configuracoes.tempo_retirada.split(' - ')[1];
            // const maiorTempo = Math.max(tempoEntrega, tempoRetirada);

            // const agendamentoCarencia = this.moment().add(maiorTempo, 'minutes');
            // const agendamentoMesmoDia = this.moment(this.agendado_data).isSame(horaAtual, 'day');

            while (horaInicio.isBefore(horaFim)) {
                const start = horaInicio.format(formato);
                horaInicio.add(intervaloHorarioMinutos, 'minutes');
                const end = horaInicio.format(formato);

                // const horaStart = this.moment(start, 'HH:mm');
                // if (agendamentoMesmoDia && horaStart.isBefore(agendamentoCarencia)) {
                //     continue;
                // }
                horarios.push(`${start} - ${end}`);
            }

            return horarios;
        },

        gerarHorarios() {
            const horarios = [];
            const { agendado_data, configuracoes, moment } = this;

            if (!agendado_data) {
                return horarios;
            }

            const dayOfWeek = moment(agendado_data).isoWeekday();
            const agendamentoHorarios = configuracoes?.agendamento.horarios;

            const {
                horario,
                segundoHorario,
                segundoHorarioAtivo
            } = agendamentoHorarios.find(e => e.isoWeekday === dayOfWeek);

            horarios.push(...this.obterHorarios(horario));

            if (segundoHorarioAtivo) {
                horarios.push(...this.obterHorarios(segundoHorario));
            }

            return horarios;
        },
    },
}
</script>

<style scoped>
.v-btn--active {
    background-color: var(--bg-color) !important;
    color: var(--color) !important;
}

.pagamento.col {
    padding: 12px 12px 0 12px;
}
</style>
