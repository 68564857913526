<template>
    <div>
        <v-btn
            class="ma-4"
            color="primary"
            fab
            bottom
            right
            @click="toggleChat"
        >
            <v-icon
                :class="{ 'shake-animation': mensagensNaoLidasCount > 0 }"
            >mdi-chat</v-icon>
            <v-badge
                v-if="mensagensNaoLidasCount"
                color="pink"
                :content="mensagensNaoLidasCount"
                offset-x="5"
                offset-y="-5"
            />
        </v-btn>

        <v-dialog v-model="chatOpen" persistent max-width="500px">
            <v-card>
                <v-card-title>
                    <v-avatar class="mr-3">
                        <v-icon>mdi-account-circle</v-icon>
                    </v-avatar>
                    <div>
                        <div class="font-weight-bold">Suporte</div>
                    </div>
                    <v-spacer/>
                    <v-btn icon @click="toggleChat">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider/>

                <v-card-text
                    v-if="!messagesLoading && !messages.length"
                    class="chat-body mt-8 text-center"
                >
                    Envie uma mensagem para iniciar um bate-papo
                </v-card-text>


                <v-card-text
                    class="chat-body"
                    ref="chatBody"
                    @scroll="onScroll"
                >
                    <template v-if="messagesLoading">
                        <v-skeleton-loader
                            v-for="i in 3"
                            :key="i"
                            class="my-2"
                            type="text"
                        />
                    </template>
                    <div
                        v-for="msg in messages"
                        :key="msg.id"
                        class="message"
                        :class="{ sent: msg.sentByMe, received: !msg.sentByMe }"
                    >
                        <div class="message-content">
                            <p v-html="formatMessage(msg.text)"/>
                            <div class="message-meta">
                                <small>{{ moment(msg.time).format('HH:mm') }}</small>
                                <v-icon v-if="msg.sentByMe" small :color="msg.read ? 'blue' : 'gray'">
                                    mdi-check-all
                                </v-icon>
                            </div>
                        </div>
                    </div>
                </v-card-text>

                <v-card-actions>
                    <!-- <v-btn icon @click="toggleEmojiPicker">
                        <v-icon>mdi-emoticon</v-icon>
                    </v-btn> -->

                    <v-textarea
                        ref="messageInput"
                        v-model="messageInput"
                        :disabled="sendLoading"
                        placeholder="Digite uma mensagem"
                        auto-grow
                        rows="1"
                        class="flex-grow-1"
                        @keyup.enter.prevent="pressEnter"
                        outlined
                        dense
                        hide-details
                    />

                    <!-- <v-btn icon @click="sendAudio">
                        <v-icon>mdi-microphone</v-icon>
                    </v-btn> -->

                    <!-- <v-btn icon @click="sendAttachment">
                        <v-icon>mdi-paperclip</v-icon>
                    </v-btn> -->

                    <v-btn
                        @click="sendMessage"
                        :loading="sendLoading"
                        icon
                        color="primary"
                    >
                        <v-icon>mdi-send</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
    data: () => ({
        chatOpen: false,
        sendLoading: false,
        messagesLoading: false,
        messageInput: '',
        page: 1,
        totalPages: 2,
        newMessageSoud: null,
    }),

    computed: {
        ...mapState([
            'messages',
            'usuario',
        ]),

        mensagensNaoLidasCount() {
            const naoLidas = this.messages.filter(e => (!e.sentByMe && !e.read));

            return naoLidas.length;
        },
    },

    async mounted() {
        document.addEventListener('chat-event', this.onChatEvent);

        this.newMessageSoud = new Audio(require('@/assets/sounds/new_message.mp3'));
    },

    beforeDestroy() {
        this.removeListeners();
    },

    watch: {
        chatOpen(newVal) {
            if (newVal) {
                this.setMessagesRead();
                this.scrollToBottom();
                setTimeout(() => this.$refs.messageInput.focus(), 100);
            }
        },

        'usuario.uuid'(v) {
            if (!v) {
                return
            }

            this.consultar();
        },
    },

    methods: {
        ...mapMutations({
            setMessages: 'setMessages',
        }),

        toggleChat() {
            this.chatOpen = !this.chatOpen;
        },

        removeListeners() {
            document.removeEventListener('chat-event', this.onChatEvent);
        },

        onChatEvent({ detail }) {
            const { message, type } = detail;

            const formattedMessage = {
                id: message.id,
                text: message.message,
                time: message.created_at,
                sentByMe: message.sent_by_me,
                read: message.status === 'lido',
            };

            if (type === 'CHAT_READ') {
                const index = this.messages.findIndex(e => e.id === message.id);
                this.messages[index] = formattedMessage;
                this.$forceUpdate();
                return;
            }

            this.messages.push(formattedMessage);
            this.$forceUpdate();

            if (!message.sent_by_me) {
                this.newMessageSoud.play();
            }

            this.scrollToBottom();

            this.chatOpen && this.setMessagesRead();
        },

        async consultar() {
            if (this.messagesLoading) return;

            this.messagesLoading = true;

            const data = {
                page: this.page,
                sender_uuid: this.usuario.uuid,
            };

            await this.$http.post('chat/messages', data)
                .then(resp => {
                    if (resp.data.type === 'warning') {
                        this.notify(resp.data.msg, 'warning');
                        return;
                    }

                    const newMessages = resp.data.data.map(e => ({
                        id: e.id,
                        text: e.message,
                        time: e.created_at,
                        sentByMe: e.sent_by_me,
                        read: e.status === 'lido',
                    }));

                    const combinedMessages = this.page === 1
                        ? newMessages
                        : [...newMessages, ...this.messages];

                    const uniqueMessages = Array.from(new Set(combinedMessages.map(msg => msg.id)))
                        .map(id => combinedMessages.find(msg => msg.id === id));

                    this.setMessages(uniqueMessages);
                    this.scrollToBottom();

                    this.totalPages = resp.data.meta.last_page;
                })
                .catch(() => this.notify('Não foi possível carregar as mensagens', 'warning'))
                .finally(() => {
                    this.messagesLoading = false;
                });
        },

        async onScroll(event) {
            const topOfList = event.target.scrollTop === 0;

            if (topOfList && !this.messagesLoading && this.page < this.totalPages) {
                this.page++;

                const chatBody = this.$refs.chatBody;
                const scroll = chatBody?.scrollHeight
                await this.consultar();

                chatBody.scrollTop = chatBody?.scrollHeight - scroll;
            }
        },

        sendMessage() {
            const message = this.messageInput.trim();

            if (!message) return;

            const data = {
                message,
                sent_by_me: true,
                sender_uuid: this.usuario.uuid,
            };

            this.sendLoading = true;
            this.$http.post('chat', data)
                .then(resp => {
                    if (resp.data.type == 'warning') {
                        this.notify(resp.data.msg, 'warning');
                        return;
                    }

                    this.messageInput = '';
                    this.scrollToBottom();
                })
                .catch(() => this.notify('Não foi possivel enviar', 'warning'))
                .finally(() => {
                    this.sendLoading = false;
                    setTimeout(() => this.$refs.messageInput.focus(), 100);
                });
        },

        setMessage(data) {
            const newMessage = {
                id: data.id,
                text: data.message,
                time: data.created_at,
                sentByMe: data.sent_by_me,
                read: data.status === 'lido',
            };

            const messages = this.messages;
            messages.push(newMessage);

            this.setMessages(messages);

            this.messageInput = '';
            this.scrollToBottom();
        },

        setMessagesRead() {
            if (this.mensagensNaoLidasCount === 0) {
                return;
            }

            const data = {
                sender_uuid: this.usuario.uuid,
                sent_by_me: false,
            };

            this.$http.post('chat/messages-set-read', data)
                .then(resp => {
                    if (resp.data.type == 'warning') {
                        this.notify(resp.data.msg, 'warning');
                        return;
                    }

                    const messages = this.messages.map(e => ({
                        ...e,
                        read: true,
                    }));

                    this.setMessages(messages);
                })
                .catch(() => this.notify('Não foi possivel enviar', 'warning'))
                .finally(() => {
                    this.sendLoading = false;
                    setTimeout(() => this.$refs.messageInput.focus(), 100);
                });
        },

        sendAudio() {
            alert("Audio recording functionality goes here.");
        },

        sendAttachment() {
            alert("Attachment functionality goes here.");
        },

        toggleEmojiPicker() {
            alert("Emoji picker functionality goes here.");
        },

        scrollToBottom() {
            setTimeout(() => {
                this.$refs.chatBody.scrollTop = this.$refs.chatBody?.scrollHeight;
            }, 300);
        },

        pressEnter(event) {
            if (event.shiftKey) {
                return;
            }

            this.sendMessage();
        },

        formatMessage(text) {
            const urlRegex = /(https?:\/\/[^\s]+)/g;
            return text?.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
        },
    },
};
</script>

<style scoped>
.chat-body {
    max-height: 400px;
    overflow-y: auto;
}
.message {
    display: flex;
    margin: 8px;
}
.message.sent {
    justify-content: flex-end;
}
.message.received {
    justify-content: flex-start;
}
.message-content {
    max-width: 70%;
    padding: 10px;
    border-radius: 10px;
    background-color: #f1f1f1;
    position: relative;
    white-space: pre-line;
}
.message.sent .message-content {
    background-color: #cce4ff;
}
.message-meta {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    font-size: 12px;
    margin-top: 4px;
}

@keyframes shake {
    0% { transform: rotate(0); }
    1% { transform: rotate(30deg); }
    3% { transform: rotate(-28deg); }
    5% { transform: rotate(34deg); }
    7% { transform: rotate(-32deg); }
    9% { transform: rotate(30deg); }
    11% { transform: rotate(-28deg); }
    13% { transform: rotate(26deg); }
    15% { transform: rotate(-24deg); }
    17% { transform: rotate(22deg); }
    19% { transform: rotate(-20deg); }
    21% { transform: rotate(18deg); }
    23% { transform: rotate(-16deg); }
    25% { transform: rotate(14deg); }
    27% { transform: rotate(-12deg); }
    29% { transform: rotate(10deg); }
    31% { transform: rotate(-8deg); }
    33% { transform: rotate(6deg); }
    35% { transform: rotate(-4deg); }
    37% { transform: rotate(2deg); }
    39% { transform: rotate(-1deg); }
    41% { transform: rotate(1deg); }
    43% { transform: rotate(0); }
    100% { transform: rotate(0); }
}

.shake-animation {
    animation: shake 4s .7s ease-in-out infinite;
}
</style>
