<template>
    <BaseContainer :hideMenu="true">
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #e0e0e0;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer />
                    <v-toolbar-title>
                        {{ selectedVideo?.title || 'Videos de Ajuda' }}
                    </v-toolbar-title>
                    <v-spacer />
                    <v-text-field
                        v-if="!selectedVideo"
                        dense
                        clearable
                        outlined
                        single-line
                        hide-details
                        v-model="search"
                        label="Pesquise.."
                        append-icon="mdi-magnify"
                    />
                </v-toolbar>
                <v-card elevation="2" class="py-4">
                    <v-card-text
                        v-if="!selectedVideo"
                        :style="`height: ${$vuetify.breakpoint.height - 120}px; overflow-x: auto;`"
                    >
                        <v-expansion-panels class="pa-2" focusable>
                            <v-expansion-panel v-for="(video, i) in filteredVideos" :key="i">
                                <v-expansion-panel-header>
                                    <b>
                                        <v-icon class="pb-1">mdi-play</v-icon>
                                        {{ video.title }}
                                    </b>
                                    <v-btn max-width="40" icon @click="shareVideo(video)" class="mr-2">
                                        <v-icon>mdi-share-variant</v-icon>
                                    </v-btn>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="pt-4">
                                    <iframe
                                        class="video-container"
                                        :src="video.url"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                    />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                    <v-card-text
                        v-else
                        class="d-flex justify-center align-center"
                    >
                        <iframe
                            class="video-container"
                            :src="selectedVideo.url"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        />
                    </v-card-text>
                </v-card>
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';

export default {
    name: 'Ajuda',

    components: { BaseContainer },

    data: () => ({
        search: '',
        videos: [],
        selectedVideo: null,
    }),

    computed: {
        filteredVideos() {
            return this.search
                ? this.videos.filter(video =>
                    video.title
                        .toLocaleLowerCase()
                        .includes(this.search.toLocaleLowerCase())
                )
                : this.videos;
        },
    },

    async mounted() {
        await this.consultar();

        const urlParams = new URLSearchParams(window.location.search);
        const videoId = urlParams.get('video');
        if (videoId) {
            this.selectedVideo = this.videos.find(video => video.id == videoId);
        }
    },

    methods: {
        async consultar() {
            try {
                this.$root.$emit('loading', true);
                const resp = await this.$http.get('videos-de-ajuda');
                this.videos = resp.data;
            } catch (error) {
                this.notify('Verifique sua conexão com a internet', 'warning');
            } finally {
                this.$root.$emit('loading', false);
            }
        },

        shareVideo(video) {
            const baseUrl = `${window.location.origin}${window.location.pathname}`;
            const videoUrl = `${baseUrl}?video=${encodeURIComponent(video.id)}`;
            this.copyToClipboard(videoUrl);
            this.notify('URL copiada para a área de transferência!', 'success');
        },

        copyToClipboard(text) {
            const input = document.createElement('textarea');
            document.body.appendChild(input);
            input.value = text;
            input.select();
            document.execCommand('copy');
            document.body.removeChild(input);
        },
    },
};
</script>

<style scoped>
.video-container {
    aspect-ratio: 16 / 9;
    width: 100%;
    max-width: 1080px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
</style>
