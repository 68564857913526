<template>
    <div>
        <v-menu offset-y v-model="isMenuOpen" max-height="450">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="searchInput"
                    v-on="on"
                    v-bind="attrs"
                    @click:append="openDialogCliente()"
                    @keypress="isMenuOpen = true"
                    :append-icon="clienteId ? 'mdi-pencil' : ''"
                    :loading="searchLoading"
                    label="Cliente"
                    placeholder="Digite para pesquisar"
                    prepend-inner-icon="mdi-magnify"
                    class="mt-4 mb-2"
                    style="min-width: 200px;"
                    dense
                    clearable
                    outlined
                    hide-details
                />
            </template>
            <v-list>
                <template v-if="clientes.length">
                    <v-list-item
                        v-for="(cliente, index) in clientes"
                        :key="index"
                        @click="onSelect(cliente)"
                    >
                        <v-list-item-title>
                            {{ cliente.id }} - {{ cliente.nome }} {{ cliente.whatsapp ? ` - ${cliente.whatsapp}` : '' }}
                        </v-list-item-title>
                    </v-list-item>
                </template>
                <v-list-item v-else style="display: flex; justify-content: center;">
                    {{ !searchLoading && searchInput && !clientes.length ? 'Nenhum registro encontrado' : 'Pesquise pelo código, nome ou whatsapp' }}
                </v-list-item>

                <v-list-item>
                    <v-btn
                        @click="openDialogCliente('reset')"
                        color="primary"
                        class="mt-2"
                        block
                        small
                    >
                        <v-icon small>mdi-account</v-icon>
                        <span class="ml-2">
                            {{ !searchLoading && searchInput && !clientes.length ? `Cadastrar ${searchInput}` : 'Novo Cliente'}}
                        </span>
                    </v-btn>
                </v-list-item>
            </v-list>
        </v-menu>

        <div
            v-if="getEnderecoCompleto"
            class="mt-1 caption"
            style="display: flex; justify-content: space-between;"
        >
            <span>{{ getEnderecoCompleto }}</span>
        </div>

        <Cliente
            :id="clienteId"
            :visible="dialogClienteVisible"
            :name="searchInput"
            @save="onSaveCliente"
            @close="closeDialogCliente"
        />
    </div>
</template>

<script>
import Cliente from '@/pages/cadastro/Cliente/Cliente.vue';

export default {
    name: 'PedidoSlider',

    components: {
        Cliente,
    },

    props: ['value'],

    data: () => ({
        isMenuOpen: false,
        searchLoading: false,
        dialogClienteVisible: false,
        clientes: [],
        cliente: null,
        searchInput: null,
    }),

    mounted() {
        this.setClienteInList();
    },

    computed: {
        clienteId() {
            return this.value?.id || null;
        },

        endereco() {
            return this.value?.enderecos?.length
                ? this.value.enderecos[0]
                : {};
        },

        getEnderecoCompleto() {
            const fields = [
                'municipio',
                'uf',
                'cep',
                'bairro',
                'rua',
                'numero',
                'referencia',
                'observacao',
            ];
            const parts = [];

            this.value?.whatsapp && parts.push(this.value.whatsapp);

            Object.keys(this.endereco).forEach(key =>
                (this.endereco[key] && typeof this.endereco[key] === 'string' && fields.includes(key) && parts.push(this.endereco[key])));

            return parts.join(', ');
        },
    },

    watch: {
        searchInput(v) {
            if (v === null) {
                this.reset();
                return;
            }

            this.startSearchTimer();
        },

        'value.id'(v) {
            v ? this.setClienteInList() : this.reset();
        },
    },

    methods: {
        setClienteInList() {
            if (!this.value?.id) {
                return;
            }

            this.onSelect(this.value);

            // const index = this.clientes.findIndex(c => c.id == this.value.id);
            // (index === -1) && this.clientes.push(this.value);

            // this.cliente = this.value;
            // this.setCliente(this.value);
        },

        onSelect(cliente) {
            if (!cliente) {
                return;
            }

            this.clientes = this.clientes.filter(e => e.id == cliente.id);

            this.$root.$emit('loading', true);
            this.$http.get(`clientes/${cliente.id}`).then(({ data }) => {
                this.setCliente(cliente);
                this.$emit('update', data);
            }).finally(() => this.$root.$emit('loading', false));
        },

        setCliente(cliente) {
            this.cliente = cliente;
            this.searchInput = `${cliente.id} - ${cliente.nome}`;
        },

        onSaveCliente(cliente) {
            this.reset();

            this.dialogClienteVisible = false;

            const index = this.clientes.findIndex(c => c.id === cliente.id);

            if (index !== -1) {
                this.clientes[index] = cliente;
            } else {
                this.clientes.push(cliente);
            }

            this.cliente = cliente;

            this.onSelect(cliente);
        },

        openDialogCliente(reset) {
            reset && this.$emit('reset');
            this.dialogClienteVisible = true;
        },

        closeDialogCliente() {
            this.dialogClienteVisible = false;
        },

        startSearchTimer() {
            const search = this.searchInput || '';
            if (search.length < 2) {
                this.clientes = [];
                return;
            }

            // clearTimeout(this.searchTimer);
            // this.searchTimer = setTimeout(this.performSearch, 1000);
            this.performSearch();
        },

        performSearch() {
            const search = this.searchInput || '';

            this.searchLoading = true;
            this.$http.post(`clientes/find?search=${search}`).then(resp => {
                this.clientes = resp.data;
            }).finally(() => (this.searchLoading = false));
        },

        reset() {
            this.clientes = [];
            this.cliente = null;
            this.searchInput = null;
            this.$emit('update', null);
        },
    },
}
</script>

<style scoped>
.v-list-item__title {
    height: 20px;
}

.v-list-item {
    height: 20px;
}
</style>
