
const installEfi = () => {
    const url = (process.env.NODE_ENV === 'production' && process.env.VUE_APP_SANDBOX !== 'true')
      ? 'https://api.gerencianet.com.br/v1/cdn/'
      : 'https://sandbox.gerencianet.com.br/v1/cdn/';

    const integrationId = '8967282822f13ac36abe6ae64638c5a2'; // PJ

    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = false;
    s.id = integrationId;
    const v = parseInt(Math.random() * 1000000);
    s.src = `${url}${integrationId}/${v}`;

    if (!document.getElementById(integrationId)) {
        document.getElementsByTagName('head')[0].appendChild(s);
    }
    window.$gn = {
        validForm: true,
        processed: false,
        done: {},
        ready: function (fn) {
            window.$gn.done = fn;
        },
    };
}

const getEfiCheckout =  async () => {
    return new Promise((resolve) => {
        if (window.$gnCheckout) {
            resolve(window.$gnCheckout);
            return;
        }
        installEfi();
            window.$gn.ready(function (checkout) {
            window.$gnCheckout = checkout;
            resolve(checkout);
        });
    });
}

export async function getPaymentToken(card) {
    const checkout = await getEfiCheckout();

    return new Promise((resolve, reject) => {
        checkout.getPaymentToken(card, (error, response) => {
            error ? reject(error) : resolve(response);
        });
    });
}
