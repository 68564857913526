import store from '../store/index';

export const clone = obj => JSON.parse(JSON.stringify(obj));

export const isMobile = () => window.matchMedia('(any-pointer:coarse)').matches;

export const printDanfe = async nota => {
    if (nota.modelo === 'nfe') {
        openNfe(nota)
        return;
    }

    const html = await fetchDanfeHtml(nota.danfe);

    const printWindow = window.open('', '_blank');
    printWindow.document.write(html);
    printWindow.print();
    window.innerWidth > 768 && printWindow.close();
};

export const openDanfe = async nota => {
    if (nota.modelo === 'nfe') {
        openNfe(nota)
        return;
    }

    const html = await fetchDanfeHtml(nota.danfe);

    const printWindow = window.open('', '_blank');
    printWindow.document.write(html);
};

const openNfe = async (nota) => {
    const password = nota.data.cliente?.cnpj || '';
    const uuid = nota.uuid;
    const token = await createSecureTokenDFe(password, uuid);

    window.open(nota.danfe + `?token=${token}`, '_blank');
};

const fetchDanfeHtml = async url => {
    try {
        const {
            access_token,
            access_token_secret,
            consumer_key,
            consumer_secret
        } = store.state.configuracoes.fiscal.credenciais;

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'X-Consumer-Key': consumer_key,
                'X-Consumer-Secret': consumer_secret,
                'X-Access-Token': access_token,
                'X-Access-Token-Secret': access_token_secret,
            }
        });

        return await response.text();
    } catch (error) {
        console.log('Failed to fetch page: ', error);
    }
}

async function createSecureTokenDFe(password, uuid) {
    password = password.replace(/[^0-9]/g, '');

    const passwordWithUUID = new TextEncoder().encode(`${password}:${uuid}`);
    const hashBuffer = await crypto.subtle.digest('SHA-256', passwordWithUUID);
    const key = await crypto.subtle.importKey(
        'raw',
        hashBuffer,
        { name: 'AES-CBC', length: 256 },
        false,
        ['encrypt']
    );

    const iv = crypto.getRandomValues(new Uint8Array(16));

    const timestampBuffer = new TextEncoder().encode(Date.now().toString());
    const encryptedDataBuffer = await crypto.subtle.encrypt(
        { name: 'AES-CBC', iv: iv },
        key,
        timestampBuffer
    );

    const encryptedData = btoa(String.fromCharCode(...new Uint8Array(encryptedDataBuffer)));
    const ivBase64 = btoa(String.fromCharCode(...iv));

    const tokenData = JSON.stringify({
        data: encryptedData,
        iv: ivBase64
    });

    return encodeURIComponent(btoa(tokenData));
}